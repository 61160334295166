@import "../../sass/style.scss";

.wrapperInfo {
  width: 100%;
  padding: 70px 0 70px 0;

  @media #{$nonDesk} {
    padding: 40px 0 0 0;
  }
}

.InfoContainer {
  @include flex($align-items: flex-start);
  margin: auto;
  gap: 100px;

  @media #{$nonDesk} {
    flex-direction: column;
    gap: 0;
    padding: 0 0 23px 0;
  }
}

.firstContainer {
  width: 50%;

  @media #{$nonDesk} {
    width: 100%;
    margin-bottom: 40px;
  }
}

.secondContainer {
  width: 50%;

  @media #{$nonDesk} {
    width: 100%;
  }
}

.titleLeft {
  display: block;
  @include subTitles($white,
    $inter,
    400,
    48px,
    left,
    30px,
    left,
    17px,
    left,
    $white );
}

.titleLeftSecond {
  margin-left: 10px;
  @include subTitles($mainColor,
    $inter,
    700,
    48px,
    left,
    30px,
    left,
    17px,
    left,
    $mainColor );
}

.paragraphStyleLeft {
  width: 100%;
  @include subTitles($white,
    $rubik,
    0,
    20px,
    left,
    17px,
    left,
    13px,
    left,
    $white );
  line-height: 28px;
}

.imageCont {
  display: none;

  @media #{$nonDesk} {
    display: block;
    width: fit-content;
    margin: auto auto 12px auto;
  }
}

.titleRight {
  display: block;
  @include subTitles($white,
    $inter,
    400,
    48px,
    right,
    30px,
    right,
    17px,
    right,
    $white );
}

.titleRightSecond {
  margin-left: 10px;
  @include subTitles($mainColor,
    $inter,
    700,
    48px,
    right,
    30px,
    right,
    17px,
    right,
    $mainColor );
}

.paragraphStyleRight {
  @include subTitles($white,
    $rubik,
    0,
    20px,
    right,
    17px,
    right,
    13px,
    right,
    $white );
  line-height: 28px;
}

.ourValueBack {
  background-image: url("../../assets/AboutUsImages/Man_holdingAboutus.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 93px 0 93px 0;
  margin: auto;

  @media #{$mobile} {
    padding: 23px 0 26px 0;
    background-image: url("../../assets/AboutUsImages/ManHolding_HomePage.jpg");
  }
}

.titleValues {
  @include mainTitleUnderline($black,
    $inter,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    2px,
    25px,
    10px,
    $black,
    1px,
    400px,
    50%,
    0,
    translate(-50%),
    200px,
    200px);

  @media #{$mobile} {
    color: $white;

    &::before {
      background-color: $white;
    }
  }
}

.ourValuesContainer {
  padding: 50px 0 30px 0;
  background-color: $mainColor;

  @media #{$mobile} {
    background-color: $black;
    padding: 27px 0 0 0;
  }
}

.valuesWrapper {
  @include flex($justify_content: space-evenly);
  margin-top: 100px;
  width: 100%;
  margin: auto;

  @media #{$mobile} {
    display: block;
  }
}

.imageTitleCont {
  width: 25%;
  margin: auto;

  @media #{$mobile} {
    width: 90%;
    margin: auto auto 40px auto;
  }
}

.circlesItem {
  margin: auto auto 17px auto;
  @include shapeWrapper(190px,
    190px,
    100%,
    1px,
    $black,
    150px,
    150px,
    102px,
    102px,
    1px,
    $white,
    160px,
    160px,
    100%,
    2px,
    $white,
    120px,
    120px,
    86px,
    86px,
    2px,
    $mainColor );

  @media #{$mobile} {
    margin-bottom: 10px;
  }
}

.iconInside {
  height: 110px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 70px;
    height: 60px;
  }

  @media #{$mobile} {
    width: 51px;
    height: 62px;
  }
}

.desktopImage {
  display: block;
}

.mobileImage {
  display: none;
}

@media #{$mobile} {
  .desktopImage {
    display: none;
  }

  .mobileImage {
    display: block;
  }
}

.firstTitleValue {
  margin-bottom: 15px;
  @include subTitles($white,
    $rubik,
    900,
    30px,
    center,
    14px,
    center,
    15px,
    center,
    $mainColor );
}

.secondTitleValue {
  @include subTitles($black,
    $rubik,
    900,
    30px,
    center,
    17px,
    center,
    15px,
    center,
    $white );
}

.paragraphStyle {
  display: block;
  width: 85%;
  height: 90px;
  margin: 20px auto;
  @include subTitles($black,
    $rubik,
    500,
    20px,
    center,
    17px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    height: auto;
    width: 100%;
  }
}

.YearsCompanyContainer {
  padding: 63px 0 70px 0;
  background-color: $white;

  @media #{$mobile} {
    padding: 50px 0 10px 0;
  }

  @media #{$tabDesk} {
    padding: 50px 0 50px 0;
  }
}

.innerContainer {
  width: 80%;
  margin: auto auto 63px auto;
  @include flex();
  gap: 200px;

  @media #{$tabDesk} {
    width: 90%;
    gap: 0px;
  }

  @media #{$mobile} {
    display: none;
  }
}

.innerContainerMobile {
  display: none;

  @media #{$mobile} {
    display: block;
    @include flex();
    margin-bottom: 30px;
  }
}

.yearsCompanyItem {
  width: 33.33%;
  margin: auto;

  @media #{$mobile} {
    width: 50%;
  }
}

.outerCircle {
  background-color: $orangeColor;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: block;
  margin: 25px auto;
  @include shapeWrapper(99px,
    99px,
    100%,
    0,
    $mainColor,
    80px,
    80px,
    90px,
    90px,
    0,
    $white,
    0,
    0,
    100%,
    0,
    $mainColor,
    0,
    0,
    0,
    0,
    0,
    $mainColor );
}

.imageInsideCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 56px;

  @media #{$tabDesk} {
    width: 40px;
  }

  @media #{$mobile} {
    width: 40px;
  }
}

.numberStyle {
  display: block;
  @include subTitles($black,
    $rubik,
    700,
    64px,
    center,
    50px,
    center,
    35px,
    center,
    $black );
}

.titleYearsStyle {
  @include subTitles($orangeColor,
    $rubik,
    400,
    16px,
    center,
    12px,
    center,
    14px,
    center,
    $orangeColor );

  @media #{$mobile} {
    display: block;
  }
}




.presenceContainer {
  padding: 70px 0 70px 0;

  @media #{$mobile} {
    padding: 20px 0 20px 0;
  }
}

.ourPresesnceTitle {
  @include mainTitleUnderline($mainColor,
    $inter,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    2px,
    25px,
    10px,
    $white,
    1px,
    620px,
    0,
    0,
    0,
    250px,
    200px);
}

.subtitles {
  display: block;
  margin: 30px 0 50px 0;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    center,
    15px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    width: 80%;
    margin: 30px auto 50px auto;
  }

  @media #{$tabDesk} {
    width: 80%;
    margin: 30px auto 50px auto;
  }
}



.ourtelcos {
  @include mainTitleUnderline($mainColor,
    $inter,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    2px,
    25px,
    10px,
    $white,
    1px,
    300px,
    50%,
    0,
    translate(-50%),
    200px,
    200px);

  &::before {
    bottom: 0px;
  }
}


.subtitles {
  display: block;
  margin: 30px 0 50px 0;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    center,
    15px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    width: 80%;
    margin: 30px auto 50px auto;
  }

  @media #{$tabDesk} {
    width: 80%;
    margin: 30px auto 50px auto;
  }
}


.subtitlesPartner {
  display: block;
  margin: 25px 0 45px 0;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    center,
    15px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    display: none;
  }

  @media #{$tabDesk} {
    width: 80%;
    margin: 30px auto 50px auto;
  }
}

.countriesCircleComponent {
  @include flex();
  gap: 151px;

  @media #{$mobile} {
    flex-direction: column-reverse;
    gap: 51px;
  }
}

.countryTitle {
  display: block;
  margin-bottom: 32px;
  @include subTitles($mainColor,
    $rubik,
    900,
    30px,
    center,
    20px,
    center,
    20px,
    center,
    $mainColor );
}

.cityComponent {
  margin-bottom: 32px;
}

.cityTitle {
  display: block;
  @include subTitles($white,
    $rubik,
    300,
    15px,
    center,
    15px,
    center,
    14px,
    center,
    $white );
}

.circlePresence {
  @include shapeWrapper(726px,
    726px,
    100%,
    4px,
    $mainColor,
    450px,
    450px,
    258px,
    258px,
    2px,
    $white,
    680px,
    680px,
    100%,
    2px,
    $mainColor,
    400px,
    400px,
    240px,
    240px,
    1px,
    $mainColor );
}

.presenceImage {
  width: 640px;
  height: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 370px;
    height: 370px;
  }

  @media #{$mobile} {
    width: 227px;
    height: 227px;
  }
}

.titlePartners {
  @media #{$mobile} {
    margin-bottom: 84px;
  }
}


.partnerComponent1 {
  background-color: $white;
  padding: 0 0 35px 0;
  @media #{$mobile} {
    padding: 40px 0 0px 0;
  }

}

.partnerComponent {
  margin-top: 58px;

  @media #{$mobile} {
    margin-top: 40px;
  }
}

.partnerMobile1 {
  @media #{$mobile} {
    padding: 0px 0 40px 0;
  }
}

.partnerMobile {
  margin: 50px 0 75px 0;

  @media #{$mobile} {
    margin: 0px 0 40px 0;
  }
}

.ourPrtners {
  @include mainTitleUnderline($mainColor,
    $inter,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    2px,
    25px,
    10px,
    $black,
    1px,
    410px,
    0,
    0,
    0,
    175px,
    210px);
  text-shadow: 0 6px 6px rgba(0, 0, 0, 0.45);

  &::before {
    bottom: 0px;
  }
}



.partnersTitle {
  @include mainTitleUnderline($mainColor,
    $rubik,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    13px,
    36px,
    10px,
    $white,
    1px,
    450px,
    50%,
    0,
    translate(-50%),
    260px,
    250px);
}

.siderComponent {
  margin-top: 57px;
}