@import "../../sass/style.scss";

.titleContainer {
  padding: 0 0 70px 0;

  @media #{$mobile} {
    padding: 20px 0 30px 0;
  }

  @media #{$tabDesk} {
    padding: 50px 0 70px 0;
  }
}

.MoobiDevTitle {
  @include mainTitleUnderline($white,
    $rubik,
    400,
    50px,
    0,
    0,
    0,
    30px,
    2px,
    30px,
    0,
    $white,
    1px,
    160px,
    50%,
    0,
    translateX(-50%),
    120px,
    120px);
}

.MoobiDevSecondTitle {
  color: $mainColor;
  font-weight: 700;
}

.allCont {
  margin: auto;
}

.titleNumberCont {
  @include flex($justify-content: flex-start);
  gap: 10px;
  padding: 0 0 70px 0;

  @media #{$mobile} {
    padding: 0 0 20px 0;
    display: block;
  }
}

.circleNumber {
  @include shapeWrapper(99px,
    99px,
    100%,
    2px,
    $mainColor,
    60px,
    60px,
    54px,
    54px,
    2px,
    $mainColor,
    75px,
    75px,
    100%,
    none,
    $white,
    50px,
    50px,
    41px,
    41px,
    none,
    $white );

  @media #{$mobile} {
    margin-bottom: 5px;
  }
}

.insideCircle {
  width: 75px;
  height: 75px;
  background-color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;

  @media #{$tabDesk} {
    width: 50px;
    height: 50px;
  }

  @media #{$mobile} {
    width: 41px;
    height: 41px;
  }
}

.numberInside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include subTitles($mainColor,
    $rubik,
    900,
    50px,
    center,
    30px,
    center,
    25px,
    center,
    $mainColor );
}

.softwareTitle {
  display: block;
  @include subTitles($white,
    $rubik,
    400,
    45px,
    left,
    25px,
    left,
    25px,
    center,
    $white );

  &.titleParagraphRight {
    @media #{$mobile} {
      text-align: center;
    }
  }
}

.softwareSecondTitle {
  color: $mainColor;
  margin-left: 10px;
  font-weight: 700;

  @media #{$mobile} {
    display: block;
    margin: 0;
  }
}

.softwareWrapper {
  @include flex($wrap: wrap);

  @media #{$mobile} {
    padding: 0 0 20px 0;
  }
}

.softwareInfoInside {
  width: 25%;

  @media #{$mobile} {
    width: 100%;
  }
}

.squaresContainer {
  padding: 0 0 70px 0;

  @media #{$mobile} {
    padding: 0;
  }
}

.imageTitleCont {
  width: 25%;
  margin: auto;

  @media #{$mobile} {
    width: 100%;
    margin: auto auto 12px auto;
  }
}

.imageTitleCont {
  @include shapeWrapper(241px,
    241px,
    28px,
    3px,
    $mainColor,
    150px,
    150px,
    139px,
    139px,
    1px,
    $mainColor,
    203px,
    203px,
    28px,
    1px,
    $white,
    120px,
    120px,
    117px,
    117px,
    1px,
    $white );
}

.SoftwareIcons {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 60px;
    height: 60px;
  }

  @media #{$mobile} {
    width: 45px;
    height: 45px;
  }
}

.titleUnderIcon {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  @include subTitles($mainColor,
    $rubik,
    700,
    14px,
    center,
    10px,
    center,
    9px,
    center,
    $mainColor );

  @media #{$tabDesk} {
    width: 80%;
  }

  @media #{$mobile} {
    width: 79%;
  }
}

.paragraphStyle {
  display: block;
  width: 80%;
  height: 155px;
  margin: 32px auto auto auto;
  @include subTitles($white,
    $rubik,
    500,
    18px,
    center,
    12px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    height: auto;
    width: 65%;
    margin: 20px auto;
  }

  @media #{$minimobile} {
    height: 180px;
    width: 50%;
    margin: auto;
  }
}

.companyTechnologyComponent {
  @include flex($justify_content: space-between);
  margin: auto;

  &.reverseDirection {
    flex-direction: row-reverse;

    @media #{$mobile} {
      flex-direction: column-reverse;
    }
  }

  @media #{$mobile} {
    flex-direction: column-reverse;
  }
}

.companyTechnologyTittleNumber {
  @include flex($justify-content: flex-start, $wrap: wrap);
  gap: 10px;

  &.circleTitleReverse {
    flex-direction: row-reverse;
  }

  @media #{$mobile} {
    margin-bottom: 20px;
    display: block;
  }
}

.companyTechnologyContainer {
  width: 50%;

  @media #{$mobile} {
    width: 100%;
  }
}

.companyTechnologyTitle {
  display: block;
  @include subTitles($white,
    $rubik,
    400,
    45px,
    left,
    25px,
    left,
    25px,
    left,
    $white );

  &.titleParagraphRight {
    text-align: right;
  }

  @media #{$mobile} {
    display: block;
    margin-left: auto;
  }
}

.companyTechnologySecondTitle {
  color: $mainColor;
  margin-left: 10px;

  @media #{$mobile} {
    display: block;
    margin: 0;
  }
}

.companyTechnologyParagraph {
  display: block;
  @include subTitles($white,
    $rubik,
    0,
    20px,
    left,
    14px,
    left,
    14px,
    center,
    $white );
  line-height: 28px;

  &.titleParagraphLeft {
    margin-left: 110px;

    @media #{$tabDesk} {
      margin-left: 70px;
    }

    @media #{$mobile} {
      margin: auto;
      width: 100%;
    }
  }

  &.titleParagraphRight {
    text-align: right;
    margin-right: 110px;

    @media #{$mobile} {
      text-align: center;
      margin: auto;
      width: 100%;

    }

    @media #{$tabDesk} {
      margin-right: 70px;
    }
  }

  @media #{$mobile} {
    display: block;
    margin: auto;
    width: 85%;
  }
}

.companyTechnologyImage {
  @include shapeWrapper(508px,
    508px,
    100%,
    4px,
    $mainColor,
    350px,
    350px,
    269px,
    269px,
    4px,
    $mainColor,
    476px,
    476px,
    100%,
    none,
    $white,
    376px,
    376px,
    196px,
    196px,
    none,
    $white );

  @media #{$mobile} {
    // margin-bottom: 26px;
    display: none;
  }
}

.imageStyle {
  width: 476px;
  height: 476px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 330px;
    height: 330px;
  }

  @media #{$mobile} {
    width: 0;
  }
}

.productManagerContainer {
  padding: 70px 0 70px 0;

  @media #{$mobile} {
    padding: 40px 0 0 0;
  }
}

.productManagerWrapper {
  @include flex($wrap: wrap);
  margin: auto;
}

.productManagerCircleTitle {
  width: 25%;
  margin-bottom: 30px;

  @media #{$mobile} {
    width: 50%;
    margin-bottom: 40px;
  }
}

.productManagerCircles {
  display: block;
  margin: 20px auto;
  @include shapeWrapper(204px,
    204px,
    100%,
    4px,
    $mainColor,
    160px,
    160px,
    54px,
    54px,
    0,
    $mainColor,
    192px,
    192px,
    100%,
    2px,
    $white,
    150px,
    150px,
    41px,
    41px,
    0,
    $white );
}

.productManagerIcons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    height: 80px;
  }

  @media #{$mobile} {
    height: 80px;
  }
}

.titleCont {
  height: 30px;
}

.productManagertitleUnderIcon {
  height: 0;
  @include subTitles($white,
    $rubik,
    500,
    16px,
    center,
    14px,
    center,
    14px,
    center,
    $white );
}

.usedTechnologyContainer {
  margin: auto;

  @media #{$mobile} {
    padding: 40px 0 0 0;
  }

}

.allUsedtechnology {
  margin: 0 0px 72px 110px;

  @media #{$mobile} {
    margin: 0 0 20px 0;
    width: 100%;
  }

  @media #{$tabDesk} {
    margin: 0 0px 72px 0px;
  }

  @media #{$ipad} {
    margin:0px;
  }
}

.titlesContainer {
  margin-bottom: 27px;
  @media #{$ipad} {
    margin:20px 0 20px 0;
  }

}

.mainTitle {
  display: block;
  @include mainTitleUnderline($white,
    $rubik,
    400,
    25px,
    0,
    0,
    auto,
    20px,
    2px,
    20px,
    0,
    $white,
    1px,
    320px,
    0,
    auto,
    0,
    200px,
    140px);

  &.underLinePrograming {
    &::before {
      width: 330px;

      @media #{$tabDesk} {
        width: 300px;
      }

      @media #{$mobile} {
        width: 110px;
      }
    }
  }

  &.underLineFrameWork {
    &::before {
      width: 310px;

      @media #{$tabDesk} {
        width: 300px;
      }

      @media #{$mobile} {
        width: 90px;
      }
    }
  }

  &.underLineUiUx {
    &::before {
      width: 250px;

      @media #{$tabDesk} {
        width: 240px;
      }

      @media #{$mobile} {
        width: 20px;
      }
    }
  }

  &.underLineDevelopment {
    &::before {
      width: 250px;

      @media #{$tabDesk} {
        width: 240px;
      }

      @media #{$mobile} {
        width: 20px;
      }
    }
  }

  &.underLineDataBase {
    &::before {
      width: 140px;

      @media #{$tabDesk} {
        width: 130px;
      }

      @media #{$mobile} {
        width: 60px;
      }
    }
  }

  &.underLineCloudPlattform {
    &::before {
      width: 340px;

      @media #{$tabDesk} {
        width: 320px;
      }

      @media #{$mobile} {
        width: 90px;
      }
    }
  }

  &.underLineDevOps {
    &::before {
      width: 280px;

      @media #{$tabDesk} {
        width: 260px;
      }

      @media #{$mobile} {
        width: 120px;
      }
    }
  }

  &.underLineMobile {
    &::before {
      width: 260px;

      @media #{$tabDesk} {
        width: 240px;
      }

      @media #{$mobile} {
        width: 140px;
      }
    }
  }

  &.underLineCybersecurity {
    &::before {
      width: 260px;

      @media #{$tabDesk} {
        width: 250px;
      }

      @media #{$mobile} {
        width: 50px;
      }
    }
  }

  &.underLineIntegration {
    &::before {
      width: 420px;

      @media #{$tabDesk} {
        width: 380px;
      }

      @media #{$mobile} {
        width: 120px;
      }
    }
  }

  &.underLineQualityAssurance {
    &::before {
      width: 440px;

      @media #{$tabDesk} {
        width: 410px;
      }

      @media #{$mobile} {
        width: 130px;
      }
    }
  }

  @media #{$mobile} {
    margin: auto;
  }

  @media #{$ipad} {
    margin: 0;

  }

}

.mainTitleSecond {
  color: $mainColor;
  margin-left: 10px;
  font-weight: 700;

  @media #{$mobile} {
    display: block;
    text-align: center;
    margin: 0;
  }
}

.mainSubTitle {
  display: block;
  margin-top: 30px;
  @include subTitles($white,
    $rubik,
    500,
    16px,
    left,
    14px,
    left,
    14px,
    center,
    $white );

  @media #{$ipad} {
    text-align: left;

  }
}

.mainSecondSubTitle {
  @media #{$mobile} {
    display: block;
    text-align: center;
  }

  @media #{$ipad} {
    text-align: left;

  }
}

.usedTechnologyWrapper {
  @include flex($justify-content: flex-start);
  gap: 20px;

  @media #{$tabDesk} {
    gap: 10px;
  }

  @media #{$mobile} {
    display: none;
  }
}

.usedTechnologyWrapper2 {
  @include flex($justify-content: flex-start);
  gap: 20px;

  @media #{$mobile} {
    justify-content: space-between;
  }

  @media #{$tabDesk} {
    gap: 10px;
  }
  
  @media #{$ipad} {
    justify-content: flex-start;
    gap: 20px;
  }

}


.usedTechnologyWrapperDown2 {
  @include flex($justify-content: flex-start);
  gap: 20px;

  @media #{$mobile} {
    justify-content: space-evenly;
  }

  @media #{$tabDesk} {
    gap: 30px;
  }

  @media #{$ipad} {
    justify-content: flex-start;
    gap: 20px;
  }
}


.usedTechnologyWrapperDownLast2 {
  display: none;

  @media #{$mobile} {
    display: flex;
    justify-content: space-evenly;
  }

  @media #{$ipad} {
    justify-content: flex-start;
    gap: 20px;
  }
}

.usedTechnologyWrapperMobile {
  display: none;

  @media #{$mobile} {
    @include flex($justify-content: space-between);
    margin-bottom: 20px;
  }

  @media #{$tablet} {
    display: none;
  }
}

.usedTechnologyWrapperMobileUnder {
  display: none;

  @media #{$mobile} {
    @include flex($justify-content: space-evenly);
    margin-bottom: 20px;
  }

  @media #{$ipad} {
    @include flex($justify-content: flex-start);
    gap: 20px;
    margin-bottom: 0;
  }
}

.usedTechnologyCircles {
  @include shapeWrapper(160px,
    160px,
    100%,
    3px,
    $mainColor,
    120px,
    120px,
    90px,
    90px,
    1px,
    $mainColor,
    75px,
    75px,
    100%,
    0,
    $white,
    50px,
    50px,
    41px,
    41px,
    0,
    $white );

  @media #{$tabDesk} {
    margin-right: 5px;
  }


  @media #{$mobile} {
    margin-right: 0;
  }
}

.usedTechnologyinsideCircle {
  background-color: $white;
  width: 145px;
  height: 145px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 110px;
    height: 110px;
  }

  @media #{$mobile} {
    width: 80px;
    height: 80px;
  }
}

.blueground {
  background-color: #087ed6;
}

.usedTechnologyIcons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 80px;
    height: 80px;
  }

  @media #{$mobile} {
    width: 50px;
    height: 50px;
  }
}

.titlesContainerSecond {

  @media #{$mobile} {
    width: 100%;

  }
}

.titlesBesideOthers {
  @include flex($justify-content: flex-start);
  gap: 20px;

  @media #{$tabDesk} {
    gap: 30px;
  }

  @media #{$mobile} {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}

.titlesBesideOthers1 {
  @include flex($justify-content: flex-start);
  gap: 160px;

  @media #{$mobile} {
    flex-direction: column;
    gap: 20px;
  }

  @media #{$tabDesk} {
    gap: 30px;
  }
}

.spaceCircles {
  margin-top: 27px;
}