@import "./sass/style.scss";

.App {
  text-align: center;
  background-color: $black;
  position: relative;
}

.container {
  width: 80%;
  margin: auto;
  @media #{$mobile} {
    width: 90%;
  }
  @media #{$tabDesk} {
    width: 95%;
  }
}

.bodyOverFlow {
  @media #{$mobile} {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
}

.btnScrolTop {
  display: none;
  @media #{$mobile} {
    display: block;
    position: fixed;
    bottom: 160px;
    right: 30px;
    color: $white;
    cursor: pointer;
    font-size: 25px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: $mainColor;
    z-index: 1000000000000;
  }
}

.arrowStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// slider Styles
.slick-next {
  right: -80px !important;
  top: 55px !important;
  @media #{$mobile} {
    right: -10px !important;
    top: 45px !important;
  }
  @media #{$tabDesk} {
    right: 10px !important;
    top: 40px !important;
  }
}

.slick-prev {
  left: -80px!important;
  top: 70px !important;
  @media #{$mobile} {
    left: -5px !important;
    top: 60px !important;
  }
  @media #{$tabDesk} {
    left: 10px !important;
  }
}

.slick-prev:before,
.slick-next:before {
  opacity: 1 !important;
}


.black-arrows .slick-next {
  width: 20px !important;
  height: 3px !important;
  background-color: $black !important;
  font-size: 0 !important;
  z-index: 1 !important;
  transform: rotate(-140deg) !important;
  &::before {
    content: "";
    display: block;
    width: 20px !important;
    height: 3px !important;
    background-color: $black;
    transform: rotate(90deg) translateY(9px) translateX(-10px);
  }
}


.black-arrows .slick-prev  {
  width: 20px !important;
  height: 3px !important;
  background-color: $black !important;
  font-size: 0 !important;
  z-index: 1 !important;
  transform: rotate(40deg) !important;
  &::before {
    content: "";
    display: block;
    width: 20px !important;
    height: 3px !important;
    background-color: $black;
    transform: rotate(90deg) translateY(9px) translateX(-10px);
  }
}


.white-arrows .slick-next {
  width: 20px !important;
  height: 3px !important;
  background-color: white !important;
  font-size: 0 !important;
  z-index: 1 !important;
  transform: rotate(-140deg) !important;
  &::before {
    content: "";
    display: block;
    width: 20px !important;
    height: 3px !important;
    background-color: white;
    transform: rotate(90deg) translateY(9px) translateX(-10px);
  }
}


.white-arrows .slick-prev {
  width: 20px !important;
  height: 3px !important;
  background-color: white !important;
  font-size: 0 !important;
  z-index: 1 !important;
  transform: rotate(40deg) !important;
  &::before {
    content: "";
    display: block;
    width: 20px !important;
    height: 3px !important;
    background-color: white;
    transform: rotate(90deg) translateY(9px) translateX(-10px);
  }
}


.slick-prev:before,
.slick-next:before {
  content: "" !important;
}

.slick-initialized .slick-slide {
  display: block !important; /* Ensure the slides display properly */
}
