@import "../../sass/style.scss";

.bothContainer {
  @include flex();
  width: 100%;
  margin: auto;
  gap: 100px;
  padding: 0 0 70px 0;

  @media #{$nonDesk} {
    flex-direction: column-reverse;
    width: 100%;
    gap: 0;
    padding: 0 0 40px 0;
  }
}

.firstCont {
  margin: auto;

  @media #{$nonDesk} {
    width: 100%;
    margin-top: 20px;
  }
}

.titleStyle {
  display: block;
  @include subTitles($white,
    $inter,
    400,
    48px,
    left,
    30px,
    left,
    17px,
    left,
    $white );
}

.secondTitle {
  @include subTitles($mainColor,
    $inter,
    700,
    48px,
    center,
    30px,
    center,
    16px,
    center,
    $mainColor );
}

.paragraph {
  width: 95%;
  margin: 10px 0 10px 0;
  @include subTitles($white,
    $rubik,
    0,
    20px,
    left,
    17px,
    left,
    13px,
    left,
    $white );
  line-height: 27px;

  @media #{$mobile} {
    line-height: 30px;
  }
}

.readMoreButton {
  background-color: transparent;
  color: $mainColor;
  border: none;
  text-decoration: underline;
  text-align: left;
  display: block;
  cursor: pointer;
  font-family: $rubik;
  font-weight: 500;
  font-size: 17px;

  &:hover,
  &:active {
    color: $white;
  }
}

.circleStyle {
  @include shapeWrapper(484px,
    484px,
    100%,
    4px,
    $mainColor,
    400px,
    400px,
    206px,
    206px,
    4px,
    $mainColor,
    453px,
    453px,
    100%,
    2px,
    $mainColor,
    380px,
    380px,
    193px,
    193px,
    2px,
    $mainColor );

  @media #{$mobile} {
    display: none;
  }
}

.imageStyle {
  width: 426px;
  height: 426px;
  border-radius: 100%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 350px;
    height: 350px;
  }
}

@media #{$mobile} {
  .desktopImage {
    max-width: 0;
  }
}

.ourValuesContainer {
  background-image: url("../../assets/HomePageImages/ManHolding_HomePage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 62px 0 70px 0;

  @media #{$mobile} {
    padding: 30px 0 0px 0;
  }
}

.titleValues {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include mainTitleUnderline($white,
    $inter,
    900,
    40px,
    20px,
    0,
    0,
    30px,
    13px,
    36px,
    10px,
    $white,
    1px,
    400px,
    50%,
    0,
    translate(-50%),
    200px,
    250px);
}

.valuesWrapper {
  @include flex($justify_content: space-between);
  margin-top: 100px;
  width: 100%;
  margin: 0 auto;

  @media #{$mobile} {
    display: block;
    gap: 44px;
    margin-top: 40px;
  }
}

.imageTitleCont {
  display: block;
  margin: 106px 0 0 0;

  @media #{$mobile} {
    margin: 40px 0;
    width: 100%;
  }
}

.circlesItem {
  display: block;
  margin: 17px auto;
  @include shapeWrapper(190px,
    190px,
    100%,
    1px,
    $black,
    150px,
    150px,
    102px,
    102px,
    1px,
    $black,
    160px,
    160px,
    100%,
    2px,
    $mainColor,
    120px,
    120px,
    86px,
    86px,
    2px,
    $mainColor );

  @media #{$mobile} {
    margin-bottom: 10px;
  }
}

.iconInside {
  height: 110px;
  object-fit: contain;
  @include middle-align();

  @media #{$tabDesk} {
    height: 60px;
  }

  @media #{$mobile} {
    height: 62px;
  }
}

.firstTitleValue {
  margin-top: 32px;
  @include subTitles($mainColor,
    $rubik,
    900,
    30px,
    center,
    17px,
    center,
    16px,
    center,
    $mainColor );
}

.secondTitleValue {
  @include subTitles($black,
    $rubik,
    900,
    30px,
    center,
    17px,
    center,
    16px,
    center,
    $black );
}

.mobiInfoContainer {
  background-color: $white;
  position: relative;
  width: 100%;

  @media #{$mobile} {
    background-color: $black;
  }
}

.backGroudnImage {
  background-image: url("../../assets/HomePageImages/backMobiImage.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
}

.mobiInfoInside {
  position: relative;
  padding: 70px 0 125px 0;
  width: 100%;
  margin: 0 auto;
  @include flex($justify_content: space-between);

  @media #{$mobile} {
    padding: 40px 0 0 0;
    display: block;
  }
}

.iconInsideMobiInfo {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$mobile} {
    width: 17px;
    height: 60px;
  }
}

.desktopImageMobiInfo,
.desktopNameLogo {
  display: block;
  width: 100%;
  margin: auto;
  height: 125px;

  @media #{$mobile} {
    display: none;
  }

  @media #{$tabDesk} {
    height: 90px;
  }
}

.mobileImageMobiInfo,
.mobileNameLogo {
  display: none;

  @media #{$mobile} {
    display: block;
    width: fit-content;
    margin: auto;
  }
}

@media #{$mobile} {

  .desktopImageMobiInfo,
  .desktopNameLogo {
    display: none;
  }

  .mobileImageMobiInfo,
  .mobileNameLogo {
    display: block;
    margin-bottom: 25px;
  }
}

.SquareTitleCont {
  width: 25%;
  margin: auto;

  @media #{$mobile} {
    width: 100%;
    margin: auto auto 40px auto;
  }
}

.squareItem {
  margin: auto;
  @include shapeWrapper(190px,
    190px,
    26px,
    1px,
    $black,
    150px,
    150px,
    94px,
    94px,
    1px,
    $white,
    160px,
    160px,
    26px,
    2px,
    $mainColor,
    120px,
    120px,
    80px,
    80px,
    2px,
    $mainColor );

  @media #{$mobile} {
    margin-bottom: 20px;
  }
}

.nameLogo {
  width: 239px;

  @media #{$tabDesk} {
    width: 150px;
  }

  @media #{$mobile} {
    width: 112px;
  }
}

.nameImageCont {
  width: fit-content;
  margin: auto;
}

.paragraphStyle {
  display: block;
  width: 100%;
  height: 200px;
  margin: auto;
  line-height: 28px;
  @include subTitles($black,
    $rubik,
    300,
    20px,
    center,
    14px,
    center,
    13px,
    center,
    $white );

  @media #{$mobile} {
    height: auto;
  }
}

.btnStyle {
  @include custom-button(150px,
    50px,
    $mainColor,
    $black,
    11px,
    0,
    transparent,
    $mainColor,
    $white,
    16px,
    $rubik,
    none,
    130px,
    30px,
    $mainColor,
    $white,
    11px,
    14px,
    transparent);

  @media #{$mobile} {
    margin-top: 20px;
  }
}

.lineSeperate {
  display: none;

  @media #{$mobile} {
    display: block;
    width: 80%;
    margin: auto;
    height: 1px;
    background-color: $mainColor;
    opacity: 0.5;
  }
}

.partnerComponent1 {
  background-color: $white;
  padding: 0 0 35px 0;

  @media #{$mobile} {
    padding: 0 0 10px 0;
  }

}

.partnerComponent {
  margin-top: 58px;

  @media #{$mobile} {
    margin-top: 40px;
  }
}

.partnerMobile1 {
  @media #{$mobile} {
    padding: 40px 0 40px 0;
  }
}

.partnerMobile {
  margin: 50px 0 75px 0;

  @media #{$mobile} {
    margin: 40px 0 40px 0;
  }
}

.ourPresesnceTitle1 {
  @include mainTitleUnderline($mainColor,
    $inter,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    2px,
    25px,
    10px,
    $black,
    1px,
    410px,
    0,
    0,
    0,
    175px,
    210px);
  text-shadow: 0 6px 6px rgba(0, 0, 0, 0.45);

  &::before {
    bottom: 0px;
  }
}

.ourPresesnceTitle {
  @include mainTitleUnderline($mainColor,
    $inter,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    2px,
    25px,
    10px,
    $white,
    1px,
    300px,
    0,
    0,
    0,
    140px,
    150px);

  &::before {
    bottom: 0px;
  }
}

.subtitlesPartner {
  display: block;
  margin: 25px 0 45px 0;
  @include subTitles($black,
    $rubik,
    500,
    20px,
    center,
    15px,
    center,
    14px,
    center,
    $black );

  @media #{$mobile} {
    display: none;
  }

  @media #{$tabDesk} {
    width: 80%;
    margin: 30px auto 50px auto;
  }
}

.partnersTitle {
  @include mainTitleUnderline($mainColor,
    $rubik,
    900,
    50px,
    20px,
    0,
    0,
    30px,
    13px,
    36px,
    10px,
    $white,
    1px,
    450px,
    50%,
    0,
    translate(-50%),
    260px,
    250px);
}

.siderComponent {
  margin-top: 57px;
}