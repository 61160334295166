@import "../../sass/style.scss";

.allCont {
  padding: 0 0 55px 0;

  @media #{$mobile} {
    padding: 0 0 20px 0;
  }
}

.featuresWrapper {
  @include flex($justify_content: space-between);
  margin: auto;

  @media #{$mobile} {
    display: none;
  }
}

.featuresWrapper1 {
  @include flex($justify_content: space-between);
  margin: auto;
  width: 80%;

  @media #{$mobile} {
    display: none;
  }
}

.featuresWrapperMobile {
  display: none;

  @media #{$mobile} {
    @include flex();
  }
}

.featuresInside {
  margin-bottom: 60px;

  @media #{$mobile} {
    width: 50%;
    margin-bottom: 0;
  }
}

.imageTitleCont {
  display: block;
  margin: 30px auto;
  @include shapeWrapper(180px,
    180px,
    100%,
    4px,
    $mainColor,
    100px,
    100px,
    80px,
    80px,
    0,
    $mainColor,
    192px,
    192px,
    100%,
    2px,
    $white,
    90px,
    90px,
    80px,
    80px,
    0,
    $white );

  @media #{$mobile} {
    width: 170px;
  }
}

.featuresIcons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 50px;
    height: 50px;
  }

  @media #{$mobile} {
    width: 70px;
    height: 70px;
  }
}

.titleUnderIcon {
  @include subTitles($white,
    $rubik,
    500,
    16px,
    center,
    12px,
    center,
    14px,
    center,
    $white );

  @media #{$tabDesk} {
    width: 10%;
  }
}

.companyGamesContainer {
  @media #{$mobile} {
    width: 95%;
    margin: auto;
  }
}

.hexaAllCont {
  @include flex();

  @media #{$mobile} {
    display: none;
  }
}

.hexaAllContMobile1 {
  display: none;

  @media #{$mobile} {
    display: block;
    @include flex($justify_content: flex-end);
  }
}

.hexaAllContMobile2 {
  display: none;

  @media #{$mobile} {
    display: block;
    @include flex($justify_content: flex-start);
  }
}

.outerBorderSmall {
  clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
  position: relative;
  position: relative;
  width: 200px;
  height: 200px;
  background-color: $white;
  margin-top: 150px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
    position: relative;
    background-color: darken($black, 0%);
    transform: scale(0.99);
  }

  @media #{$tabDesk} {
    width: 150px;
    height: 150px;
  }

  @media #{$mobile} {
    width: 80px;
    height: 80px;
  }
}

.outerBorderSmallThird {
  clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
  position: relative;
  position: relative;
  width: 200px;
  height: 200px;
  background-color: $white;
  margin-top: -120px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
    position: relative;
    background-color: darken($black, 0%);
    transform: scale(0.99);
  }

  @media #{$tabDesk} {
    width: 150px;
    height: 150px;
    margin-top: -80px;
  }

  @media #{$mobile} {
    width: 80px;
    height: 80px;
    margin-top: 0;
  }
}

.outerBorderLargeFirst {
  clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
  position: relative;
  position: relative;
  width: 560px;
  height: 560px;
  background-color: $mainColor;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
    position: relative;
    background-color: darken($black, 0%);
    transform: scale(0.99);
  }

  @media #{$tabDesk} {
    width: 350px;
    height: 350px;
  }

  @media #{$mobile} {
    width: 300px;
    height: 300px;
  }
}

.outerBorderLargeSecond {
  clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
  position: relative;
  position: relative;
  width: 560px;
  height: 560px;
  background-color: $mainColor;
  margin-top: -130px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
    position: relative;
    background-color: darken($black, 0%);
    transform: scale(0.99);
  }

  @media #{$tabDesk} {
    width: 350px;
    height: 350px;
    margin-top: -50px;
  }

  @media #{$mobile} {
    width: 300px;
    height: 300px;
    margin-top: 0;
  }
}

.hexaInnerSmall {
  clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
  position: relative;
  width: 180px;
  height: 180px;
  background-color: $mainColor;
  margin-bottom: -115px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
    position: relative;
    background-color: darken($black, 0%);
    transform: scale(0.95);
  }

  @media #{$tabDesk} {
    width: 120px;
    height: 120px;
  }

  @media #{$mobile} {
    width: 70px;
    height: 70px;
    margin-bottom: 0;
  }
}

.hexaInnerLarge {
  clip-path: polygon(13.546% 76.681%, 42.592% 93.171%, 42.592% 93.171%, 44.081% 93.941%, 45.559% 94.541%, 47.032% 94.972%, 48.5% 95.232%, 49.969% 95.321%, 51.44% 95.238%, 52.916% 94.982%, 54.401% 94.553%, 55.897% 93.95%, 57.408% 93.171%, 86.454% 76.681%, 86.454% 76.681%, 87.568% 76.011%, 88.575% 75.315%, 89.472% 74.572%, 90.257% 73.758%, 90.927% 72.852%, 91.48% 71.83%, 91.914% 70.669%, 92.227% 69.349%, 92.417% 67.844%, 92.48% 66.134%, 92.48% 33.573%, 92.48% 33.573%, 92.416% 31.912%, 92.226% 30.455%, 91.914% 29.179%, 91.487% 28.064%, 90.948% 27.086%, 90.302% 26.223%, 89.554% 25.454%, 88.709% 24.756%, 87.772% 24.108%, 86.747% 23.486%, 57.617% 6.913%, 57.617% 6.913%, 56.066% 6.111%, 54.526% 5.49%, 52.995% 5.048%, 51.471% 4.785%, 49.953% 4.7%, 48.439% 4.791%, 46.926% 5.059%, 45.414% 5.502%, 43.9% 6.121%, 42.383% 6.913%, 13.253% 23.486%, 13.253% 23.486%, 12.228% 24.108%, 11.291% 24.756%, 10.446% 25.454%, 9.698% 26.223%, 9.052% 27.086%, 8.513% 28.064%, 8.086% 29.179%, 7.774% 30.455%, 7.584% 31.912%, 7.52% 33.573%, 7.52% 66.134%, 7.52% 66.134%, 7.583% 67.844%, 7.773% 69.349%, 8.086% 70.669%, 8.52% 71.83%, 9.073% 72.852%, 9.744% 73.758%, 10.528% 74.572%, 11.425% 75.315%, 12.432% 76.011%, 13.546% 76.681%);
  position: relative;
  width: 500px;
  height: 500px;
  background-color: $white;
  margin-bottom: -115px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include flex();

  @media #{$tabDesk} {
    width: 320px;
    height: 320px;
  }

  @media #{$mobile} {
    width: 260px;
    height: 260px;
  }
}

.numberInside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include subTitles($white,
    $rubik,
    300,
    100px,
    center,
    50px,
    center,
    25px,
    center,
    $white );
}

.hexagoninfo {
  position: relative;
  top: -5%;

  @media #{$mobile} {
    position: relative;
    top: 0;
  }
}

.iconStyle {
  @media #{$tabDesk} {
    width: 80px;
  }

  @media #{$mobile} {
    width: 60px;
  }
}

.hexaTitle {
  @include subTitles($black,
    $rubik,
    400,
    40px,
    center,
    25px,
    center,
    14px,
    center,
    $black );
}

.hexaSecondTitle {
  color: $mainColor;
}

.hexaParagraph {
  display: block;
  width: 80%;
  margin: 20px auto;
  @include subTitles($black,
    $rubik,
    500,
    16px,
    center,
    12px,
    center,
    9px,
    center,
    $black );

  @media #{$tabDesk} {
    margin: 10px auto;
  }

}