@import "../../sass/style.scss";

.MainBannerStyle {
  padding: 20px 0 70px 0;
  position: relative;
  width: 100%;
  @media #{$mobile} {
    padding: 10px 0 40px 0;
  }
}
  
.bannerStyle {
  width: 80%;
  object-fit: contain;
  @media #{$mobile} {
    width: 90%;
  }
}

.titleBanner {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include subTitles(
    $white,
    $rubik,
    700,
    65px,
    center,
    40px,
    center,
    20px,
    center,
    $white
  );
  @media #{$mobile} {
    width: 90%;
  }
  &.titleSubTitle {
    @media #{$mobile} {
      top: 45%;
    }
  }
}

.firstInfo {
  width: 100%;
}

.secondInfo {
  width: 50%;
  margin: auto;
  @media #{$minimobile} {
    width: 80%;
  }
}

.titleSecondPart {
  color: $mainColor;
}

.subTitles {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 70%;
  @include subTitles(
    $white,
    $rubik,
    400,
    20px,
    center,
    15px,
    center,
    14px,
    center,
    $white
  );

  @media #{$mobile} {
    position: relative;
    left: 0;
    top: 100%;
    transform: none;
    line-height: 14px;

  }
  @media #{$minimobile} {
    font-size: 10px;
  }
}
