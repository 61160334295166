@import "../../sass/style.scss";

.allComponent {
  padding: 0;

  @media #{$mobile} {
    padding: 40px 0 0 0;
  }
}

.paymentMethodComponent{
  padding: 0;
  @media #{$mobile} {
    padding: 50px 0 0 0;
  }
}

.mainTitle {
  display: block;
  @include mainTitleUnderline($white,
    $rubik,
    400,
    50px,
    0,
    auto,
    auto,
    30px,
    2px,
    20px,
    0,
    $white,
    1px,
    320px,
    50%,
    0,
    translateX(-50%),
    200px,
    140px);
}

.secondMainTitle {
  color: $mainColor;
  margin-left: 5px;
  font-weight: 700;

  @media #{$mobile} {
    display: block;
  }
}

.secondMainTitleDown {
  color: $mainColor;
  margin-left: 5px;

  @media #{$mobile} {
    display: block;
  }
}

.coreFeaturesDetails {
  margin-top: 64px;

  @media #{$mobile} {
    margin-top: 53px;
  }
}

.coreFeaturesWrapper {
  @include flex($justify-content: space-between);
  margin: 70px auto auto auto;

  @media #{$mobile} {
    margin: 20px auto auto auto;
    display: none;
  }
}

.coreFeaturesWrapper1 {
  @include flex($justify-content: space-between);
  margin: auto;
  width: 80%;

  @media #{$mobile} {
    margin: 20px auto auto auto;
    display: none;

  }
}

.coreFeaturesWrapperMobile1 {
  display: none;

  @media #{$mobile} {
    @include flex();
    margin: 20px auto auto auto;

  }
}

.coreFeaturesinfoInside {
  width: 18%;
  margin-bottom: 100px;

  @media #{$mobile} {
    width: 50%;
    margin-bottom: 20px;
  }
}

.coreFeaturesinfoInside1 {
  width: 25%;
  margin-bottom: 100px;

  @media #{$mobile} {
    width: 50%;
    margin-bottom: 20px;
  }
}

.securityInfoInside {
  margin-bottom: 100px;

  @media #{$mobile} {
    width: 50%;
    margin-bottom: 20px;
  }
}

.coreFeaturesCircle {
  margin: 23px auto;
  @include shapeWrapper(204px,
    204px,
    100%,
    4px,
    $mainColor,
    140px,
    140px,
    54px,
    54px,
    0,
    $mainColor,
    192px,
    192px,
    100%,
    2px,
    $white,
    130px,
    130px,
    41px,
    41px,
    0,
    $white );

  @media #{$mobile} {
    margin: 36px auto;
  }
}

.coreFeaturesIcons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  @media #{$tabDesk} {
    height: 70px;
  }
}

.titleUnderIcon {
  width: 100%;
  margin: auto;
  height: 0;
  display: block;
  @include subTitles($white,
    $rubik,
    500,
    16px,
    center,
    14px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    height: 50px;
  }

  @media #{$minimobile} {
    height: 120px;
  }
}

.featurestTitleUnderIcon {
  @include subTitles($white,
    $rubik,
    500,
    16px,
    center,
    14px,
    center,
    14px,
    center,
    $white );
}

.paymenProcessCont {
  margin-top: 100px;

  @media #{$mobile} {
    margin-top: 50px;
  }
}

.paymentProcessWrapper {
  @include flex( $wrap: wrap);
  width: 92%;
  margin: 45px auto;

  @media #{$tabDesk} {
    width: 95%;
    margin: auto;
  }

  @media #{$mobile} {
    display: block;
    margin: auto;
    width: 95%;
  }
}

.paymentProcessWrapperMobile {
  display: none;

  @media #{$mobile} {
    @include flex($justify-content: space-evenly, $direction: column);
    width: 80%;
    margin: auto;
  }
}

.paymentProcessWrapper1 {
  @include flex($justify-content: space-evenly);
  width: 65%;
  margin: auto;

  @media #{$mobile} {
    display: block;
    width: 90%;
  }
}

.paymentProcessinfoInside {
  width: 33.33%;
  margin: auto;
  display: block;

  @media #{$mobile} {
    @include flex($direction: column);
    width: 100%;
  }
}

.paymentProcessinfoInsideMiddle {
  width: 50%;
  margin: auto;
  display: block;

  @media #{$mobile} {
    @include flex($direction: column);
    width: 100%;
  }
}

.halfCircle {
  @include halfCircleNumber();
  position: absolute;
  left: 20%;
  top: 13%;

  @media #{$tabDesk} {
    left: 14%;
    top: 5%;
  }
}

.numberInside {
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: 1;
  transform: rotate(-90deg) translate(90%, -25%);
  @include subTitles($white,
    $rubik,
    900,
    50px,
    center,
    30px,
    center,
    30px,
    center,
    $white );
}

.paymentProcessCircle {
  display: block;
  margin: 55px auto;
  @include shapeWrapper(271px,
    271px,
    100%,
    3px,
    #da5026,
    150px,
    150px,
    161px,
    161px,
    3px,
    #da5026,
    233px,
    233px,
    100%,
    0,
    $white,
    180px,
    180px,
    140px,
    140px,
    0,
    $white );
}

.whiteCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 100%;
  width: 233px;
  height: 233px;

  @media #{$mobile} {
    width: 139px;
    height: 139px;
  }

  @media #{$tabDesk} {
    width: 140px;
    height: 140px;
  }
}

.paymentProcessIcons {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$mobile} {
    width: 50px;
    height: 50px;
  }

  @media #{$tabDesk} {
    width: 70px;
    height: 70px;
  }
}

.paymentProcesstTitleUnderIcon {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  @include subTitles($mainColor,
    $rubik,
    500,
    12px,
    center,
    14px,
    center,
    12px,
    center,
    $mainColor );
}

.paragraphStyle {
  display: block;
  height: 0;
  margin-bottom: 120px;
  width: 90%;
  @include subTitles($white,
    $rubik,
    500,
    18px,
    center,
    14px,
    center,
    14px,
    center,
    $white );

  @media #{$tabDesk} {
    width: 100%;
  }

  @media #{$mobile} {
    width: 100%;
    margin-bottom: 50px;
  }
}