@import "../../sass/style.scss";

.infoContainer {
  padding: 28px 0 80px 0;
  margin: auto;

  @media #{$mobile} {
    padding: 31px 0 30px 0;
  }
}

.firstInfoCont {
  display: block;
  width: fit-content;

  @media #{$mobile} {
    margin: auto;
  }
}

.titlePublication {
  @include subTitles($white,
    $inter,
    400,
    48px,
    left,
    30px,
    left,
    17px,
    center,
    $white );
}

.titleSecondPublication {
  @include subTitles($mainColor,
    $inter,
    700,
    48px,
    left,
    30px,
    left,
    17px,
    center,
    $mainColor );
}

.paragraphPublication {
  width: 100%;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    left,
    17px,
    left,
    13px,
    center,
    $white );
  line-height: 28px;
}

.booksContainer{
  display: grid
}


.PublicationsBooksContainer {
  margin: auto;
}

.booksWrapper {
  @include flex($justify-content: space-between);
  margin: auto;
  gap: 100px;

  @media #{$nonDesk} {
    display: block;
  }
}

.squareParagraph {
  @media #{$nonDesk} {
    margin-bottom: 69px;

  }
}

.bookSquare {
  position: relative;
  @include shapeWrapper(100%,
    535px,
    60px,
    3px,
    $mainColor,
    480px,
    480px,
    236px,
    227px,
    3px,
    $mainColor,
    535px,
    513px,
    none,
    none,
    transparent,
    450px,
    450px,
    209px,
    201px,
    none,
    transparent);
  display: block;
  margin: auto;

  @media #{$mobile} {
    border-radius: 20px;
    margin: auto;
  }

  @media #{$tabDesk} {
    margin: auto;
  }
}

.imageInside {
  width: 90%;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  @media #{$mobile} {
    width: 209px;
    height: 201px;
    border-radius: 20px;
  }

  @media #{$tabDesk} {
    width: 450px;
    height: 450px;
  }

  @media #{$ipad} {
    width: 200px;
    height: 200px;
  }
}

.paragraphStyle {
  width: 95%;
  margin: 32px auto 20px auto;
  display: block;
  line-height: 28px;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    left,
    17px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    line-height: 14px;
    width: 100%;
  }

  @media #{$tabDesk} {
    width: 100%;
  }
}

.btnStyle {
  @include custom-button(150px,
    50px,
    $mainColor,
    $white,
    11px,
    0,
    transparent,
    $mainColor,
    $white,
    16px,
    $rubik,
    none,
    100px,
    30px,
    $mainColor,
    $white,
    11px,
    14px,
    transparent);
}

.detailsPublication{
  max-width: 1300px;
  margin: auto;
}