@mixin custom-button(
  $width,
  $height,
  $color,
  $background,
  $borderRadius,
  $borderWidth,
  $borderColor,
  $hoverBackground,
  $hoverlabel,
  $fontSize,
  $fontFamily,
  $textTransform,
  $widthMobile,
  $heightMobile,
  $colorMobile,
  $backgroundMobile,
  $borderRadiusMobile,
  $fontSizeMobile,
  $borderColorMobile
) {
  width: $width;
  height: $height;
  color: $color;
  background: $background;
  border-style: solid;
  border-radius: $borderRadius;
  border-width: $borderWidth;
  border-color: $borderColor;
  font-size: $fontSize;
  font-family: $fontFamily;
  text-transform: $textTransform;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-weight: 700;
  cursor: pointer;
  @media #{$mobile} {
    width: $widthMobile;
    height: $heightMobile;
    color: $colorMobile;
    background: $backgroundMobile;
    border-radius: $borderRadiusMobile;
    font-size: $fontSizeMobile;
    border-color: $borderColorMobile;
  }

  &:hover {
    border-color: $borderColor;
    background: $hoverBackground;
    color: $hoverlabel;
  }
}

@mixin shapeWrapper(
  $width,
  $height,
  $borderRaduis,
  $borderWidth,
  $borderColor,
  $widthTablet,
  $heightTablet,
  $widthMobile,
  $heightMobile,
  $borderWidthMobile,
  $borderColorMobile,
  $widthBefore,
  $heightBefore,
  $borderRaduisBefore,
  $borderWidthBefore,
  $borderColorBefore,
  $widthTabletBefore,
  $heightTabletBefore,
  $widthMobileBefore,
  $heightMobileBefore,
  $borderWidthMobileBefore,
  $borderColorMobileBefore
) {
  width: $width;
  height: $height;
  border-radius: $borderRaduis;
  border: $borderWidth solid $borderColor;
  position: relative;
  @media #{$tabDesk} {
    width: $widthTablet;
    height: $heightTablet;
  }
  @media #{$mobile} {
    width: $widthMobile;
    height: $heightMobile;
    border: $borderWidthMobile solid $borderColorMobile;
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: $borderWidthBefore solid $borderColorBefore;
    width: $widthBefore;
    height: $heightBefore;
    border-radius: $borderRaduisBefore;
    z-index: 0;
    @media #{$tabDesk} {
      width: $widthTabletBefore;
      height: $heightTabletBefore;
    }
    @media #{$mobile} {
      width: $widthMobileBefore;
      height: $heightMobileBefore;
      border: $borderWidthMobileBefore solid $borderColorMobileBefore;
    }
  }
}

@mixin halfCircleNumber() {
  width: 340px;
  height: 170px;
  background-color: transparent;
  border-top: 3px solid $mainColor;
  border-left: 3px solid $mainColor;
  border-right: 3px solid $mainColor;
  border-bottom: none;
  border-radius: 170px 170px 0 0;
  transform: rotate(90deg);
  &::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 83px;
    height: 83px;
    border-radius: 100%;
    background-color: $mainColor;
    z-index: 0;
    @media #{$tabDesk} {
      width: 50px;
      height: 50px;
    }
    @media #{$mobile} {
      width: 50px;
      height: 50px;
    }
  }
  @media #{$mobile} {
    width: 206px;
    height: 103px;
    border-radius: 103px 103px 0 0;
  }
  @media #{$tabDesk} {
    width: 200px;
    height: 120px;
    border-radius: 150px 150px 0 0;
  }
}

@mixin mainTitleUnderline(
  $color,
  $fontFamily,
  $fontWeight,
  $fontSize,
  $letterSpace,
  $marginLeft,
  $marinRight,
  $fontSizeTablet,
  $letterSpaceTablet,
  $fontSizeMobile,
  $letterSpaceMobile,
  $underlineColor,
  $underLineHeight,
  $underLineWidth,
  $left,
  $right,
  $transform,
  $underLineWidthTablet,
  $underLineWidthMobile
) {
  color: $color;
  font-family: $fontFamily;
  font-size: $fontSize;
  letter-spacing: $letterSpace;
  position: relative;
  width: fit-content;
  margin-left: $marginLeft;
  margin-right: $marinRight;
  font-weight: $fontWeight;
  @media #{$tabDesk} {
    font-size: $fontSizeTablet;
    letter-spacing: $letterSpaceTablet;
  }
  @media #{$mobile} {
    font-size: $fontSizeMobile;
    letter-spacing: $letterSpaceMobile;
  }

  &::before {
    content: "";
    display: block;
    width: $underLineWidth;
    height: $underLineHeight;
    background-color: $underlineColor;
    position: absolute;
    left: $left;
    right: $right;
    transform: $transform;
    bottom: -12px;
    @media #{$tabDesk} {
      width: $underLineWidthTablet;
    }
    @media #{$mobile} {
      width: $underLineWidthMobile;
      left: 50%;
      right: 0;
      transform: translateX(-50%);
    }
  }
}

@mixin subTitles(
  $color,
  $fontFamily,
  $fontWeight,
  $fontSize,
  $textAlign,
  $fontSizeTablet,
  $textAlignTablet,
  $fontSizeMobile,
  $textAlignMobile,
  $colorMobile
) {
  color: $color;
  font-family: $fontFamily;
  font-size: $fontSize;
  text-align: $textAlign;
  font-weight: $fontWeight;
  @media #{$tabDesk} {
    font-size: $fontSizeTablet;
    text-align: $textAlignTablet;
  }
  @media #{$mobile} {
    font-size: $fontSizeMobile;
    text-align: $textAlignMobile;
    color: $colorMobile;
  }
}
