@import "../../sass/style.scss";

.productTeamPosition {
  padding: 0 0 70px 0;

  @media #{$mobile} {
    padding: 0 0 40px 0;
  }
}

.ProductTeamContainer {
  width: 60%;
  margin: auto;
  background-color: $mainColor;
  padding: 60px;
  border-radius: 32px;

  @media #{$mobile} {
    padding: 14px 1px 54px 1px;
    width: 90%;
  }
}

.title {
  margin: auto auto 23px auto;
  display: block;
  width: fit-content;
  @include subTitles($white,
    $rubik,
    400,
    48px,
    center,
    30px,
    center,
    24px,
    center,
    $white );

  @media #{$mobile} {
    width: 90%;
  }
}

.subTitle {
  margin: 0 10px;
  @include subTitles($black,
    $rubik,
    400,
    48px,
    left,
    30px,
    left,
    24px,
    center,
    $black );
}

.discription {
  @include subTitles($white,
    $rubik,
    400,
    24px,
    center,
    18px,
    center,
    14px,
    center,
    $white );

  @media #{$mobile} {
    width: 90%;
    margin: auto auto 27px auto;
    line-height: 14px;
  }
}

.inputsPosition {
  @include flex();
  width: 80%;
  margin: auto;
  gap: 70px;
  margin-bottom: 10px;

  @media #{$mobile} {
    flex-direction: column;
    width: 90%;
    gap: 0;
    margin: auto;
  }
}

.titleInputStyle {
  width: 100%;

}


.TitelDesign {
  @include subTitles($white,
    $inter,
    300,
    16px,
    left,
    12px,
    left,
    12px,
    left,
    $white );
  display: block;
  @media #{$mobile} {
    margin-left: 3px;
  }
}


.inputFields,
.inputFieldsArea {
  width: 100%;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: $white;
  color: $white;
  margin-bottom: 20px;

  &::placeholder {
    @include subTitles($white,
      $inter,
      300,
      16px,
      left,
      12px,
      left,
      12px,
      left,
      $white );

    @media #{$mobile} {
      padding: 5px 5px;
    }

  }

  @media #{$mobile} {
    margin-bottom: 15px;
  }

  &.inputFieldsArea {
    border-width: 2px;
    max-height: 50px;
    min-width: 100%;
    max-width: 100%;
    @media #{$tabDesk} {
      max-height: 40px;
    }


    @media #{$mobile} {
      max-width: 100%;
      border-left-color: $white;
      border-right-color: $white;
      border-top-color: $white;
      border-radius: 10px;
      height: 80px;
      margin-top: 5px;
    }
  }


}

.checkBoxBtn {
  width: 80%;
  margin: 25px auto 20px auto;

  @media #{$mobile} {
    display: block;
  }
}

.inputTitle {
  @include flex($justify_content: flex-start, $align-items: 0);

  @media #{$mobile} {
    margin: -20px 0 17px 0;
  }
}

.checkboxInput {
  width: 17px;
  height: 17px;
}

.checkboxLabel {
  @include subTitles($white,
    $inter,
    300,
    18px,
    left,
    14px,
    left,
    10px,
    left,
    $white );
}

.subWords {
  color: #f87565;
  margin-left: 5px;
}

.btnStyle {
  @include custom-button(80%,
    62px,
    $black,
    $white,
    12px,
    1px,
    $white,
    $black,
    $white,
    18px,
    $rubik,
    none,
    256px,
    38px,
    $black,
    $white,
    1px,
    16px,
    $white );

  @media #{$mobile} {
    border-radius: 12px;
  }
}

.emailPosition {
  margin: auto auto 70px auto;

  @media #{$mobile} {
    margin: auto;
  }
}

.emailSentence {
  @include subTitles($white,
    $rubik,
    400,
    24px,
    center,
    18px,
    center,
    15px,
    center,
    $white );
  line-height: 35px;

  @media #{$mobile} {
    margin: auto;
    line-height: 14px;
  }
}

.emailoffice {
  @include subTitles($white,
    $rubik,
    400,
    18px,
    left,
    18px,
    left,
    18px,
    center,
    $white );

  &:hover,
  &:active {
    color: $mainColor;
  }
}

.emailSentenceSecond {
  color: $mainColor;
  cursor: pointer;
  margin: auto 5px auto 5px;

  &:hover,
  &:active {
    color: $white;
  }
}

.mapTitlesContainer {
  @include flex();
  width: 80%;
  margin: auto;
  gap: 109px;

  @media #{$mobile} {
    display: block;
    width: 100%;
  }
}

.mapSquare {
  width: 581px;
  height: 581px;
  border-radius: 67px;
  background-color: $white;

  iframe {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  @media #{$tabDesk} {
    width: 450px;
    height: 450px;
  }

  @media #{$mobile} {
    width: 262px;
    height: 185px;
    border-radius: 12px;
    display: block;
    margin: 45px auto;
  }
}

.titleMap {
  margin-bottom: 18px;
  display: block;
  @include mainTitleUnderline($white,
    $inter,
    400,
    32px,
    0,
    0,
    0,
    20px,
    2px,
    32px,
    0,
    $mainColor,
    2px,
    80px,
    0,
    0,
    0,
    120px,
    120px);

  &::before {
    bottom: 0;
  }

  @media #{$mobile} {
    width: auto;

    &::before {
      bottom: -5px;
    }
  }

  @media #{$tabDesk} {
    &::before {
      bottom: -5px;
    }
  }
}

.subTitleMap {
  margin-bottom: 10px;
  display: block;
  @include subTitles($mainColor,
    $rubik,
    400,
    18px,
    left,
    18px,
    left,
    18px,
    center,
    $mainColor );
}

.underSubTitleMap {
  margin-bottom: 16px;
  display: block;
  @include subTitles($white,
    $rubik,
    400,
    18px,
    left,
    18px,
    left,
    18px,
    center,
    $white );
}

.subTitleLocation {
  margin-bottom: 10px;
  display: block;
  @include subTitles($white,
    $inter,
    400,
    20px,
    left,
    18px,
    left,
    18px,
    center,
    $white );
}

.messagePosition {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.message {
  text-transform: uppercase;
  margin: 20px auto;
  @include subTitles($white,
    $rubik,
    400,
    18px,
    center,
    16px,
    center,
    12px,
    center,
    $white );
}