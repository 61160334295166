@import "../../sass/style.scss";

.loader_container {
  margin: 10px auto 10px;
  border: 5px solid #808080;
  border-top: 5px solid $white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: relative;
  @media #{$mobile} {
    width: 20px;
    height: 20px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
