@import "../../sass/style.scss";

.titleMoobiVault {
  padding: 70px 0 70px 0;
  margin: auto;

  @media #{$mobile} {
    padding: 20px 0 20px 0;
  }
}

.firstTitle {
  display: block;
  @include subTitles($white,
    $rubik,
    400,
    48px,
    center,
    30px,
    center,
    25px,
    center,
    $white );
}

.secondTitle {
  color: $mainColor;
  font-weight: 700;
}

.paragraphStyle {
  display: block;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    center,
    16px,
    center,
    15px,
    center,
    $white );
  line-height: 28px;
}

.LoanManagmentContainer {
  padding: 0 0 166px 0;

  @media #{$mobile} {
    padding: 0 0 20px 0;

  }
}

.titleParagraph {
  margin: auto;

  @media #{$mobile} {
    width: 100%;
  }
}

.LoanManagmentTitle {
  @include mainTitleUnderline($white,
    $rubik,
    400,
    50px,
    6px,
    0,
    0,
    30px,
    2px,
    30px,
    16px,
    $white,
    1px,
    750px,
    50%,
    0,
    translateX(-50%),
    350px,
    300px);

  @media #{$mobile} {
    display: block;
    letter-spacing: 10px;
  }

  @media #{$ipad} {
    width: 100%;
  }
}

.LoanManagmentTitleSecond {
  color: $mainColor;
  font-weight: 700;
}

.LoanManagmentParagraph {
  margin: 50px auto auto auto;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    center,
    16px,
    center,
    14px,
    center,
    $white );
  line-height: 28px;
}

.loadManagmentSystem {
  padding: 0 0 141px 0;
  width: 95%;
  margin: auto;

  @media #{$mobile} {
    padding: 0 0 40px 0;
  }
}

.arowContainer {
  width: 50%;
}

.arrowStyleBusiness {
  width: 90%;
  margin: auto;
  background-color: white;
  height: 1px;
  position: relative;

  @media #{$tabDesk} {
    width: 95%;
  }

  @media #{$mobile} {
    position: relative;
    transform: rotate(90deg);
    width: 41px;
    margin-bottom: -5px;

    &.none {
      display: none;
    }

    &.block1 {
      top: 50px;

      @media #{$ipad} {
        left: 85%;
      }

    }

    &.block2 {
      top: 50px;

      @media #{$ipad} {
        left: 85%;
      }
    }

    &.block3 {
      top: 50px;

      @media #{$ipad} {
        left: 85%;
      }
    }

    &.block4 {
      top: 50px;

      @media #{$ipad} {
        left: 85%;
      }
    }

    &.block5 {
      top: -160px;
      left: 0%;
    }
  }

  &.top {
    transform: rotate(-30deg) translateY(50px) translateX(-30px);
  }

  &.bottom {
    transform: rotate(30deg) translateY(-50px) translateX(-30px);
  }


  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $white;
    display: inline-block;
    transform: rotate(40deg);
    top: -4px;
    right: 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $white;
    display: inline-block;
    transform: rotate(40deg);
    top: -4px;
    left: 0;
  }
}


.arrowStyle {
  background-color: $white;
  position: absolute;
  height: 2px;

  &.arrowLeft1 {
    left: 19%;
    top: 80%;
    width: 11%;
    transform: rotate(25deg);

    @media #{$mobile} {
      left: 45%;
      top: 40%;
      width: 41px;
      transform: rotate(90deg) translate(-50%, -50%);
    }
  }

  &.arrowRight1 {
    right: 19%;
    top: 80%;
    width: 11%;
    transform: rotate(-25deg);

    @media #{$mobile} {
      display: none;
    }
  }

  &.arrowLeft2 {
    left: 21%;
    top: 30%;
    width: 8%;
    transform: rotate(-10deg);

    @media #{$mobile} {
      left: 45%;
      top: 20%;
      width: 41px;
      transform: rotate(90deg) translate(-50%, -50%);
    }
  }

  &.arrowRight2 {
    right: 21%;
    top: 30%;
    width: 8%;
    transform: rotate(10deg);

    @media #{$mobile} {
      display: none;
    }
  }

  &.arrowLeft3 {
    left: 19%;
    top: -10%;
    width: 17%;
    transform: rotate(-40deg);

    @media #{$mobile} {
      left: 45%;
      top: 30%;
      width: 41px;
      transform: rotate(90deg) translate(-50%, -50%);
    }
  }

  &.arrowRight3 {
    right: 19%;
    top: -10%;
    width: 17%;
    transform: rotate(40deg);

    @media #{$mobile} {
      display: none;
    }
  }

  &.arrowLeft4 {
    left: 15%;
    top: -51%;
    width: 30%;
    transform: rotate(-60deg);

    @media #{$mobile} {
      left: 45%;
      top: 30%;
      width: 41px;
      transform: rotate(90deg) translate(-50%, -50%);
    }
  }

  &.arrowRight4 {
    right: 15%;
    top: -51%;
    width: 30%;
    transform: rotate(60deg);

    @media #{$mobile} {
      display: none;
    }
  }

  &.arrowCenter {
    left: 40%;
    top: -80%;
    width: 17%;
    transform: rotate(90deg);

    @media #{$mobile} {
      left: 45%;
      top: 10%;
      width: 41px;
      transform: rotate(90deg) translate(-50%, -50%);
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $white;
    display: inline-block;
    transform: rotate(40deg);
    top: -4px;
    right: 0;

  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: $white;
    display: inline-block;
    transform: rotate(40deg);
    top: -4px;
    left: 0;
  }

  @media #{$mobile} {
    height: 1px;
  }
}

.arrowStyleMiddleBusiness {
  width: 20%;
  margin: auto;
  position: relative;
  height: 100vh;

  @media #{$mobile} {
    height: auto;
  }

  @media #{$tabDesk} {
    height: 50vh;
  }
}


.LoanCenterCircle {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 174px;
  @include shapeWrapper(414px,
    414px,
    100%,
    4px,
    $mainColor,
    300px,
    300px,
    200px,
    200px,
    4px,
    $mainColor,
    388px,
    388px,
    100%,
    2px,
    $white,
    280px,
    280px,
    187px,
    187px,
    2px,
    $white );

  @media #{$mobile} {
    margin: 0 0 0px 0;
    transform: translate(-50%, 0%);
  }
}

.managmentWrapper {
  @include flex($justify_content: space-between);
  width: 85%;
  margin: -750px auto auto auto;
  position: relative;

  @media #{$tabDesk} {
    margin: -550px auto auto auto;
    width: 100%;
  }

  @media #{$mobile} {
    margin: 0 auto auto auto;
    width: 100%;
  }
}

.managmentWrapper1 {
  @include flex($justify_content: space-between);
  width: 85%;
  margin: 20px auto auto auto;
  position: relative;

  @media #{$tabDesk} {
    width: 100%;
  }

  @media #{$mobile} {
    margin: 0;
    width: 100%;
  }
}

.managmentWrapper2 {
  @include flex($justify_content: space-between);
  width: 75%;
  margin: 20px auto auto auto;
  position: relative;

  @media #{$tabDesk} {
    width: 90%;
  }

  @media #{$mobile} {
    margin: 0 auto auto auto;
    width: 100%;
  }
}

.managmentWrapper3 {
  @include flex($justify_content: space-between);
  width: 55%;
  margin: -20px auto auto auto;
  position: relative;

  @media #{$tabDesk} {
    width: 80%;
    margin: 0px auto auto auto;
  }

  @media #{$mobile} {
    margin: 0 auto auto auto;
    width: 100%;
  }
}

.managmentWrapper4 {
  @include flex($justify_content: center);
  width: 100%;
  margin: -150px auto auto auto;
  position: relative;

  @media #{$tabDesk} {
    margin: -140px auto auto auto;
  }

  @media #{$mobile} {
    margin: 0 auto auto auto;
    width: 100%;
  }
}

.imageTitleCont {
  @include shapeWrapper(204px,
    204px,
    100%,
    4px,
    $mainColor,
    150px,
    150px,
    170px,
    170px,
    none,
    $mainColor,
    192px,
    192px,
    100%,
    2px,
    $white,
    140px,
    140px,
    100px,
    100px,
    none,
    $white );

  @media #{$mobile} {
    border-width: 0;

    &::before {
      border-width: 0;
    }
  }
}

.LoanIcons {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media #{$mobile} {
    height: 80px;
  }

  @media #{$tabDesk} {
    height: 60px;
  }
}

.titleUnderIcon {
  display: block;
  margin-top: 13px;
  @include subTitles($white,
    $rubik,
    500,
    16px,
    center,
    14px,
    center,
    12px,
    center,
    $white );

  @media #{$mobile} {
    width: 70%;
    margin: -30px auto auto auto;
  }
}

.moduleContainer {
  padding: 0 0 166px 0;

  @media #{$mobile} {
    padding: 0 0 50px 0;
  }
}

.ModulesWrapper {
  @include flex($justify_content: space-between);
  margin: auto;

  @media #{$mobile} {
    flex-direction: column;
    gap: 30px;
  }
}

.squareTitleCont {
  background-color: $white;
  margin-bottom: 34px;
  outline: none;
  @include shapeWrapper(192px,
    192px,
    28px,
    0,
    $white,
    120px,
    120px,
    152px,
    152px,
    0,
    $white,
    192px,
    192px,
    0,
    none,
    $white,
    120px,
    120px,
    192px,
    192px,
    0,
    $white );
  box-shadow: 0 8px 4px rgba(0, 0, 0, 0.45);

  &::before {
    border: none;
  }

  @media #{$mobile} {
    margin-bottom: 15px;
  }
}

.ModuleIcons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 114px;

  @media #{$tabDesk} {
    width: 70px;
    height: 70px;
  }

  @media #{$mobile} {
    width: 70px;
    height: 70px;
  }
}

.ModuletitleUnderIcon {
  @include subTitles($mainColor,
    $rubik,
    900,
    18px,
    center,
    14px,
    center,
    18px,
    center,
    $mainColor );
}

.businessContainerTitles {
  margin: 0 auto;
  width: 90%;
  position: relative;

  @media #{$tabDesk} {
    width: 95%;
  }

  @media #{$mobile} {
    padding: 0 0 16px 0;
  }
}

.businessTitle {
  display: block;
  margin-bottom: 50px;
  @include mainTitleUnderline($white,
    $rubik,
    400,
    50px,
    10px,
    0,
    auto,
    30px,
    2px,
    25px,
    10px,
    $white,
    1px,
    600px,
    0,
    0,
    0,
    350px,
    300px);

  @media #{$mobile} {
    display: block;
    margin-left: auto;
  }
}

.businessTitleSecond {
  color: $mainColor;
  font-weight: 700;
}

.businessParagraph {
  width: 60%;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
  @include subTitles($white,
    $rubik,
    500,
    20px,
    left,
    14px,
    left,
    14px,
    center,
    $white );
  line-height: 28px;

  @media #{$mobile} {
    display: block;
    margin-left: auto;
    width: 100%;
  }
}

.businessCircles {
  @include flex($justify_content: space-between);
  padding: 0 0 70px 0;
  width: 90%;
  margin: auto;

  @media #{$tabDesk} {
    width: 95%;
  }

  @media #{$mobile} {
    flex-direction: column;
    padding: 0 0 50px 0;
  }

}

.leftCircleStyle {
  overflow: hidden;
  @include shapeWrapper(420px,
    420px,
    100%,
    4px,
    $mainColor,
    212px,
    212px,
    212px,
    212px,
    4px,
    $mainColor,
    400px,
    400px,
    100%,
    2px,
    $white,
    199px,
    199px,
    199px,
    199px,
    2px,
    $white );
}

.payLaterImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;

  @media #{$mobile} {
    width: 199px;
    height: 199px;
  }

  @media #{$tabDesk} {
    width: 199px;
    height: 199px;
  }
}

.loanCenterImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 350px;

  @media #{$mobile} {
    width: 180px;
    height: 180px;
  }

  @media #{$tabDesk} {
    width: 250px;
    height: 250px;
  }

  @media #{$ipad} {
    width: 180px;
    height: 180px;
  }
}

.BusinessContainer {
  @include flex($justify_content: space-around);
}

.test {
  @media #{$mobile} {
    width: 170px;
    height: 170px;
  }
}

.bigCircleBusiness {
  @media #{$mobile} {
    // margin-bottom: 50px;
  }
}

.businessWrapper {
  @include flex($justify_content: flex-end);

  @media #{$mobile} {
    display: none;
  }
}

.BusinessWrapperMobile {
  display: none;

  @media #{$mobile} {
    @include flex();
    // margin-top: -40px;
  }
}


.allBusinessDigital {
  width: 100%
}

.BusinessimageTitleCont {
  margin: 57px 0 10px 0;
  @include shapeWrapper(176px,
    176px,
    100%,
    4px,
    $mainColor,
    120px,
    120px,
    170px,
    170px,
    none,
    $mainColor,
    165px,
    165px,
    100%,
    2px,
    $white,
    100px,
    100px,
    100px,
    100px,
    none,
    $white );

  @media #{$mobile} {
    border-width: 0;
    margin: 0;

    &::before {
      border-width: 0;
    }
  }
}

.iconTitle {
  @include flex();

  @media #{$mobile} {
    width: 170px;
    height: 170px;
  }

}

.digitalContainer {
  @include flex($justify_content: space-between);
  margin: auto;
  width: 90%;

  @media #{$tabDesk} {
    width: 95%;
  }

  @media #{$mobile} {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

.digitalWrapper {
  @include flex();
  gap: 40px;
  margin-bottom: 64px;

  @media #{$mobile} {
    gap: 0;
    margin-bottom: 0;
    display: none;
  }

  @media #{$tabDesk} {
    margin-bottom: 20px;
  }
}

.digitalWrapperMobile {
  display: none;

  @media #{$mobile} {
    @include flex($justify_content: space-around);
  }
}

.digitalWrapperMobile1 {
  display: none;

  @media #{$mobile} {
    @include flex();
  }
}

.digitalimageTitleCont {
  @include shapeWrapper(176px,
    176px,
    100%,
    4px,
    $mainColor,
    120px,
    120px,
    170px,
    170px,
    0,
    $mainColor,
    165px,
    165px,
    100%,
    2px,
    $white,
    140px,
    140px,
    100px,
    100px,
    0,
    $white );

  @media #{$mobile} {
    width: 80px;
    display: block;
    margin: auto;
  }
}

.titleCircleDigital {
  @include flex($direction: column, $align-items: flex-end);

  @media #{$nonDesk} {
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
  }
}

.digitalTitle {
  display: block;
  margin: 0 0 50px auto;
  @include mainTitleUnderline($white,
    $rubik,
    400,
    50px,
    10px,
    auto,
    0,
    20px,
    2px,
    25px,
    10px,
    $white,
    1px,
    450px,
    auto,
    0,
    0,
    200px,
    300px);

  @media #{$mobile} {
    margin: 0 auto 36px auto;
    text-align: center;

    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.digitalTitleTitleSecond {
  color: $mainColor;
  font-weight: 700;
}

.digitalTitleParagraph {
  margin-left: auto;
  @include subTitles($white,
    $rubik,
    0,
    20px,
    right,
    12px,
    right,
    14px,
    center,
    $white );
  line-height: 28px;
  width: 75%;

  @media #{$tabDesk} {
    margin: auto auto 32px auto;
    width: 90%;
  }

  @media #{$mobile} {
    margin: auto auto 32px auto;
    width: 100%;
  }
}

.digitalBigCircel {
  display: block;
  margin: auto;
  @include shapeWrapper(450px,
    450px,
    100%,
    4px,
    $mainColor,
    212px,
    212px,
    273px,
    273px,
    4px,
    $mainColor,
    400px,
    400px,
    100%,
    0,
    $white,
    199px,
    199px,
    187px,
    187px,
    0,
    $white );
}

.imageStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;

  @media #{$tabDesk} {
    width: 180px;
    height: 180px;
  }

  @media #{$mobile} {
    width: 255px;
    height: 255px;
  }
}