@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

//Fonts
$rubik: "Rubik", sans-serif;
$inter: "Inter", sans-serif;

// Colors
$white: #ffffff;
$black: #101018;
$mainColor: #dc5126;
$orangeColor:#DA5026;


// Media Queries
$minimobile: "(max-width: 345px)";
$smallmobile: "(max-width: 359px)";
$mediummobile: "(max-width: 390px)";
$mobile: "(max-width: 768px)";
$mobileP: "(max-width: 479px)";
$mobileL: "(min-width: 480px) and (max-width: 768px)";
$nonMob: "(min-width: 768px)";
$nonPortable: "(min-width: 992px)";
$tablet: "(min-width: 768px) and (max-width: 991px)";
$smallDesktop: "(min-width: 992px) and (max-width: 1199px)";
$desktop: "(min-width: 1200px)";
$largeDesktop: "(min-width: 1366px)";
$mobTab: "(max-width: 991px)";
$tabDesk: "(min-width: 768px) and (max-width: 1199px)";
$nonDesk: "(max-width: 1199px)";
$ipad: "(device-width: 768px) and (device-height: 1024px)";
