@import "../../sass/style.scss";

.allCont {
  @include flex();
  position: relative;

  @media #{$mobile} {
    flex-direction: column;
    background-color: $white;
  }
}

.firstCont {
  width: 50%;
  background-color: $white;
  position: relative;
  height: 500px;

  @media #{$mobile} {
    width: 100%;
    height: 300px;
  }
}

.secondCont {
  width: 50%;
  background-color: $mainColor;
  height: 500px;
  position: relative;

  @media #{$mobile} {
    width: 100%;
    height: 500px;
    width: 100%;
    background: $mainColor;
    clip-path: polygon(0% 100%, 0% 20%, 100% 0%, 100% 100%);
    padding: 50px 0 130px 0;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #101018;
  border-width: 0 49.4vw 260px 0;
  position: absolute;
  z-index: 1000;

  @media #{$tabDesk} {
    border-width: 0 49.3vw 260px 0;
  }

  @media #{$tablet} {
    border-width: 0 49vw 260px 0;
  }

  &.right {
    right: 0;
    transform: rotate(180deg);
    z-index: 0;

    @media #{$mobile} {
      transform: rotate(180deg) translateX(0px);
      border-width: 0 100vw 130px 0;
    }
  }

  &.left {
    left: 0;
    transform: rotateX(180deg) translateX(-1px);

    @media #{$mobile} {
      display: none;
    }
  }
}

.triangle3 {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #dc5126;
  border-width: 0 0 240px 10vw;
  transform-origin: 0 0;
  position: absolute;
  bottom: 0;
  right: 0;

  @media #{$mobile} {
    display: none;
  }
}

.checkBtn {
  position: relative;
  z-index: 10;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include shapeWrapper(318px,
    318px,
    100%,
    2px,
    $mainColor,
    250px,
    250px,
    217px,
    217px,
    4px,
    $mainColor,
    298px,
    298px,
    100%,
    2px,
    $black,
    220px,
    220px,
    203px,
    203px,
    2px,
    $black );

  @media #{$mobile} {
    top: 70%;
  }

  .btnInner {
    width: 298px;
    height: 298px;
    border-radius: 100%;
    cursor: pointer;
    @include flex();
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{$tabDesk} {
      width: 220px;
      height: 220px;
    }

    @media #{$mobile} {
      width: 203px;
      height: 203px;
    }

    &:hover,
    &:active {
      color: $white;
      background-color: $mainColor;
    }
  }
}

.btnText {
  font-size: 44px;
  font-family: $rubik;
  font-weight: 500;
  color: $black;
  text-align: center;

  @media #{$mobile} {
    font-size: 25px;
  }

  @media #{$tabDesk} {
    font-size: 25px;
  }
}

.rectangle {
  width: 70%;
  height: 435px;
  background: $black;
  position: relative;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  top: 57%;
  left: 40%;
  transform: translate(-50%, -50%);
  @include flex();
  clip-path: polygon(0.792% 46.568%,
      0.792% 46.568%,
      0.831% 45.73%,
      0.945% 44.919%,
      1.13% 44.144%,
      1.382% 43.411%,
      1.697% 42.729%,
      2.071% 42.105%,
      2.498% 41.548%,
      2.977% 41.064%,
      3.501% 40.663%,
      4.068% 40.352%,
      92.979% 0.35%,
      92.979% 0.35%,
      93.9% 0.068%,
      94.806% 0.039%,
      95.679% 0.243%,
      96.499% 0.66%,
      97.245% 1.272%,
      97.9% 2.059%,
      98.443% 3.001%,
      98.855% 4.079%,
      99.116% 5.274%,
      99.208% 6.566%,
      99.208% 91.144%,
      99.208% 91.144%,
      99.146% 92.205%,
      98.966% 93.211%,
      98.677% 94.15%,
      98.291% 95.007%,
      97.816% 95.769%,
      97.262% 96.422%,
      96.639% 96.954%,
      95.958% 97.351%,
      95.226% 97.598%,
      94.455% 97.684%,
      5.545% 97.684%,
      5.545% 97.684%,
      4.774% 97.598%,
      4.042% 97.351%,
      3.361% 96.954%,
      2.738% 96.422%,
      2.184% 95.769%,
      1.709% 95.007%,
      1.323% 94.15%,
      1.034% 93.211%,
      0.854% 92.205%,
      0.792% 91.144%,
      0.792% 46.568%);
  margin: 0 auto;

  @media #{$mobile} {
    height: 586px;
    clip-path: polygon(1.356% 20.413%,
        1.356% 20.413%,
        1.42% 19.852%,
        1.607% 19.309%,
        1.91% 18.788%,
        2.324% 18.294%,
        2.841% 17.834%,
        3.456% 17.41%,
        4.161% 17.03%,
        4.95% 16.697%,
        5.817% 16.418%,
        6.755% 16.196%,
        87.772% 0.271%,
        87.772% 0.271%,
        89.364% 0.053%,
        90.937% 0.013%,
        92.457% 0.138%,
        93.888% 0.414%,
        95.195% 0.828%,
        96.343% 1.366%,
        97.297% 2.015%,
        98.022% 2.761%,
        98.483% 3.589%,
        98.644% 4.488%,
        98.644% 93.937%,
        98.644% 93.937%,
        98.538% 94.663%,
        98.229% 95.352%,
        97.736% 95.994%,
        97.074% 96.581%,
        96.261% 97.103%,
        95.313% 97.55%,
        94.247% 97.914%,
        93.08% 98.186%,
        91.828% 98.356%,
        90.508% 98.414%,
        9.492% 98.414%,
        9.492% 98.414%,
        8.172% 98.356%,
        6.92% 98.186%,
        5.753% 97.914%,
        4.687% 97.55%,
        3.739% 97.103%,
        2.926% 96.581%,
        2.264% 95.994%,
        1.771% 95.352%,
        1.462% 94.663%,
        1.356% 93.937%,
        1.356% 61.567%,
        1.356% 39.412%,
        1.356% 20.413%);
    top: 60%;
    width: 90%;
    left: 45%;
  }
}

.mLogoLetter {
  position: absolute;
  top: 15%;
  right: 10%;

  @media #{$tabDesk} {
    height: 70px;
  }

  @media #{$mobile} {
    height: 61px;
  }
}

.infoInside {
  text-align: center;
  color: $white;
  width: 90%;
  margin-top: 100px;
}

.titleContainer {
  display: block;
  text-align: left;

  @media #{$mobile} {
    margin-bottom: 20px;
  }
}

.title {
  @include subTitles($white,
    $inter,
    400,
    16px,
    right,
    14px,
    right,
    14px,
    right,
    $white );

  @media #{$mobile} {
    margin-bottom: 5px;
  }

  @media #{$tabDesk} {
    margin-bottom: 0;
  }
}

.subTitle {
  margin: 4px;
  @include subTitles($mainColor,
    $inter,
    400,
    16px,
    right,
    14px,
    right,
    14px,
    right,
    $mainColor );
}

.inputsPosition {
  @include flex();
  gap: 70px;
  margin-bottom: 15px;

  @media #{$mobile} {
    flex-direction: column;
    width: 100%;
    gap: 0;
    margin: 0;
  }

  @media #{$tabDesk} {
    width: 100%;
    gap: 10px;
    margin: 0;
  }
}

.inputFields {
  width: 100%;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: $white;
  color: $white;
  padding: 8px 0;

  &::placeholder {
    font-size: 12px;
    font-family: $inter;
    font-weight: 300;
    color: $white;
  }

  @media #{$mobile} {
    padding: 0 0 16px 0;
    margin-bottom: 25px;
    margin-right: auto;
  }
}

.checkBoxBtn {
  @include flex($justify_content: flex-start);
  gap: 7px;
  margin-top: 25px;

  @media #{$mobile} {
    display: block;
  }
}

.inputTitle {
  @include flex($justify_content: flex-start, $align-items: 0);
  gap: 7px;

  @media #{$mobile} {
    margin: -20px 0 17px 0;
  }
}

.checkboxInput {
  width: 17px;
  height: 17px;
}

.checkboxLabel {
  @include subTitles($white,
    $inter,
    300,
    10px,
    center,
    8px,
    center,
    12px,
    center,
    $white );
}

.subWords {
  margin: 2px;
  @include subTitles($mainColor,
    $inter,
    300,
    10px,
    center,
    8px,
    center,
    14px,
    center,
    $mainColor );
}

.btnPosition {
  width: fit-content;
  margin: auto;
}

.btnStyle {
  @include custom-button(161px,
    39px,
    $mainColor,
    transparent,
    11px,
    1px,
    $white,
    $mainColor,
    $white,
    10px,
    $rubik,
    none,
    210px,
    40px,
    $mainColor,
    $black,
    11px,
    10px,
    $white );
}


.message {
  margin: 20px auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @include subTitles($white,
    $rubik,
    400,
    12px,
    center,
    8px,
    center,
    12px,
    center,
    $white );

  @media #{$mobile} {
    margin: 10px auto;
  }
}