@import "../../sass/style.scss";

.allBanner {
  @include flex();
  height: 250px;
  width: 100%;
  background-color: $black;
  position: relative;
  overflow: hidden;
  @media #{$tabDesk} {
    height: 150px; 
  }
  @media #{$mobile} {
    height: 100px;
  }
}

.logoIcon {
  height: 112px;
  @media #{$tabDesk} {
    height: 80px;
  }
  @media #{$tablet} {
    height: 50px;
  }
  @media #{$mobile} {
    height: 33px;
  }
}

.mobiVaultLogoIcon {
  height: 112px;
  @media #{$tabDesk} {
    height: 80px;
  }
  @media #{$tablet} {
    height: 80px;
  }
  @media #{$mobile} {
    height: 33px;
  }
}


.logoName {
  height: 100px;
  margin-left: 40px;
  @media #{$tabDesk} {
    height: 80px;
    margin-left: 20px;
  }
  @media #{$tablet} {
    height: 50px;
    margin-left: 20px;
  }
  @media #{$mobile} {
    height: 33px;
    margin-left: 20px;
  }
}

.mobiVaultLogoName {
  height: 100px;
  @media #{$tabDesk} {
    height: 80px;
    margin-left: 20px;
  }
  @media #{$tablet} {
    height: 70px;
    margin-left: 20px;
  }
  @media #{$mobile} {
    height: 33px;
  }
}

.logoIconRight {
  height: 250px;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media #{$tabDesk} {
    height: 150px;
  }
  
  @media #{$mobile} {
    height: 100px;
  }
}

.logoIconLeft {
  height: 250px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media #{$tabDesk} {
    height: 150px;
  }
  @media #{$mobile} {
    height: 100px;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  z-index: 100;
  border-width: 0 20vw 125px 0;
  @media #{$tabDesk} {
    border-width: 0 15vw 75px 0;
  }
  @media #{$mobile} {
    border-width: 0 20vw 50px 0;
  }
  &.firsttriangle {
    right: 0;
    transform: rotateY(180deg);
    right: 0;
    border-color: transparent transparent $mainColor;
  }
  &.secondtriangle {
    left: 0;
    transform: rotateX(180deg);
    right: 0;
    border-color: transparent transparent $white;
  }
}