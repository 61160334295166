@import "../../sass/style.scss";

.header {
  background-color: $black;
  width: 100%;
  position: relative;
  z-index: 1000000;
}

.topHeader {
  @include flex($justify_content: space-between);
  padding: 15px 0 15px 0;
  width: 80%;
  margin: auto;

  @media #{$mobile} {
    justify-content: space-between;
    margin-left: 14px;
  }
}

.logo {
  height: 80px;

  @media #{$mobile} {
    height: 55px;
  }

  @media #{$tabDesk} {
    height: 60px;
  }
}

.logoMobile {
  @media #{$mobile} {
    z-index: 100000;
  }
}

.iconClose {
  cursor: pointer;
}

.ul_list {
  @include flex($justify_item: 0);
  list-style: none;
  margin: 0;
  padding: 0;

  @media #{$mobile} {
    display: block;
    margin: 130px 0 0 25px;
  }
}

.list_word {
  font-size: 17px;
  font-family: $rubik;
  font-weight: 600;
  color: $white;
  text-decoration: none;
  display: inline-block;
  margin: 0 80px 0 0;
  width: 100%;

  @media #{$mobile} {
    padding: 0;
    margin: 0 0 40px 0;
    font-size: 16px;
    position: relative;
    display: block;
    text-align: left;
  }

  @media #{$tabDesk} {
    font-size: 13px;
    margin: 0 20px 0px 0;
  }

  @media #{$ipad} {
    font-size: 13px;
    margin: 0 20px 50px 0;
  }

  &:hover,
  &:active {
    color: $mainColor;
  }

}


.navigateTabsPrivacy {
  display: none;

  @media #{$mobile} {
    display: block;
  }
}

.menulist {
  @media #{$mobile} {
    position: fixed;
    width: 100%;
    top: 0;
    right: -100%;
    background-color: $black;
    height: 100vh;
    transition: right 0.6s ease;

    &.open {
      right: 0;
    }

    &.close {
      right: -110%;
    }
  }
}


.burgermenuContainer {
  display: none;

  @media #{$mobile} {
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    right: 14px;
    top: 24px;
    z-index: 100000;
  }

  i {
    @media #{$mobile} {
      margin: 2px;
      border-radius: 2px;
      transition: all ease 0.5s;
      cursor: pointer;
      background-color: $white;
    }
  }
}

.openbtn:nth-child(1) {
  @media #{$mobile} {
    transform: rotate(45deg) translateY(10px) translateX(3px);
    width: 30px;
    height: 3px;
    border-radius: 10px;
  }
}

.openbtn:nth-child(2) {
  opacity: 0;
}

.openbtn:nth-child(3) {
  @media #{$mobile} {
    transform: rotate(-45deg) translateY(-5px) translateX(-3px);
    width: 30px;
    height: 3px;
    border-radius: 10px;

  }
}

.closebtn:nth-child(1) {
  transform: rotate(0) translateY(0);
  width: 20px;
  height: 3px;
  border-radius: 10px;

}

.closebtn:nth-child(2) {
  opacity: 1;
  width: 30px;
  height: 3px;
}

.closebtn:nth-child(3) {
  transform: rotate(0) translateY(0);
  width: 40px;
  height: 3px;
}

.dropdownContent {
  opacity: 0;
  overflow: hidden;
  transition: 0.6s ease, opacity 0.6s ease;
  position: absolute;
  width: 170px;
  background-color: $white;
  max-height: 0;
  transform: translateY(-150%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    max-height 0.3s ease-in-out;
  overflow: hidden;
  z-index: 10;
  border-radius: 16px;

  @media #{$tabDesk} {
    width: 120px;
  }


  @media #{$mobile} {
    max-height: 0;
    position: relative;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    transform: translateY(0%);
  }

  &.visible {
    opacity: 1;
    max-height: 230px;
    width: 170px;
    opacity: 1;
    transform: translateY(0);
    top: auto;
    border: 1px solid $white;
    border-radius: 16px;
    // padding: 20px 15px 20px 15px;
    box-sizing: border-box;

    @media #{$mobile} {
      max-height: 230px;
      padding: 0;
      border-radius: 0;
      border: 0;
      width: 100%;
    }

    @media #{$tabDesk} {
      width: 120px;
    }

    @media #{$ipad} {
      width: 100%;
    }
  }
}

.dropDownList {
  list-style: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  @media #{$mobile} {
    margin-bottom: 30px;
    width: 100%;
  }
}

.lineSeperateList {
  width: 90%;
  height: 1px;
  background-color: $mainColor;
  margin: auto;

  @media #{$mobile} {
    background-color: $white;
    width: 80%;
    display: block;
    margin: auto 0;
    opacity: 0.1;
  }

  @media #{$ipad} {
    width: 80%;
  }
}

.lineSeperateListEnd {
  display: none;

  @media #{$mobile} {
    background-color: $white;
    width: 80%;
    height: 1px;
    display: block;
    margin: auto 0;
    opacity: 0.1;
  }

  @media #{$ipad} {
    width: 80%;
  }
}

.logoIcon {
  margin-right: 30px;

  @media #{$mobile} {
    display: none;
  }

  @media #{$tabDesk} {
    margin-right: 5px;
  }
}

.linkStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 15px 0;
  text-decoration: none;
  font-family: $rubik;
  font-weight: 400;
  font-size: 14px;
  color: $black;

  @media #{$mobile} {
    font-size: 14px;
    font-family: $rubik;
    font-weight: 500;
    color: $white;
    display: block;
    text-align: left;
    padding: 0 0 15px 0;
    opacity: 0.7;
  }

  @media #{$tabDesk} {
    font-size: 12px;
  }

  &:hover,
  &:active {
    color: $mainColor;
  }

}

.linkStyle1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 15px 0;
  text-decoration: none;
  font-family: $rubik;
  font-weight: 400;
  font-size: 14px;
  color: $black;

  @media #{$mobile} {
    font-size: 14px;
    font-family: $rubik;
    font-weight: 500;
    color: $white;
    display: block;
    text-align: left;
    opacity: 0.7;
  }

  @media #{$tabDesk} {
    font-size: 12px;
  }

  &:hover,
  &:active {
    color: $mainColor;
  }

}

.listWordList {
  @include flex();
  width: 100%;
  position: relative;

  @media #{$mobile} {
    justify-content: flex-start;
  }
}

.iconClose {
  @media #{$mobile} {
    transition: transform 0.3s ease-in-out;

    &.up {
      transform: rotate(180deg);
    }
  }
}

.mediaIcon {
  width: 25px;
  height: 25px;

  &:hover {
    filter: brightness(0) saturate(100%) invert(26%) sepia(88%) saturate(5611%) hue-rotate(349deg) brightness(92%) contrast(104%);
  }
}

.mediaPositions {
  display: none;

  @media #{$mobile} {
    @include flex($justify_content: space-evenly);
  }
}


.footerStyle {
  display: none;

  @media #{$mobile} {
    display: block;
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
}