@import "../../sass/style.scss";

.logoContainer {
  @include flex(center, center);
  min-height: 110px;
  width: 100%;

  @media #{$mobile} {
    width: 85%;
    margin: auto 20px;

  }
}

.logoContainerSecond {
  @include flex(center, center);
  min-height: 140px;
  width: 100%;

  @media #{$mobile} {
    width: 85%;
    margin: auto 20px;
    min-height: 110px;

  }
}

.logoContainerLonaci {
  @include flex(center, center);
  min-height: 110px;
  width: 100%;

  @media #{$mobile} {
    width: 85%;
    margin: auto 20px;

  }
}

.logoContainerHalote {
  @include flex(center, center);
  min-height: 140px;
  width: 100%;

  @media #{$mobile} {
    width: 85%;
    margin: auto 40px;
    min-height: 110px;

  }
}

.logoContainerLonace {
  @include flex(center, center);
  min-height: 70px;
  width: 100%;

  @media #{$mobile} {
    width: 85%;
    margin: auto 20px;
    min-height: 80px;

  }
}

.logoContainerDigitain {
  @include flex(center, center);
  width: 100%;

  @media #{$mobile} {
    width: 100%;
    display: block;
  }
}


.logoIcon {
  width: 80px;

  @media #{$mobile} {
    max-height: 50px;
    display: block;
    width: 150px;
  }
}

.africellLogoIcon,
.evolutionGamingLogoIcon {
  width: 150px;

  @media #{$mobile} {
    max-height: 55px;
    display: block;
    width: 180px;
  }
}

.logoXbetlogo,
.logoDigitainlogo {
  width: 180px;
  object-fit: contain;
  margin: auto;

  @media #{$mobile} {
    max-height: 20px;
    display: block;
    width: 140px;
  }
}

.logoVodacom,
.logoSafaricom {
  width: 200px;

  @media #{$mobile} {
    max-height: 100px;
    display: block;

  }
}


.SanadlogoIcon {
  width: 180px;

  @media #{$mobile} {
    max-height: 80px;
    display: block;
    width: 120px;
  }
}



.GR8TechLogoIcon {
  width: 150px;

  @media #{$mobile} {
    max-height: 45px;
    display: block;
    width: 180px;
  }
}

.airtelLogoIcon {
  width: 200px;

  @media #{$mobile} {
    max-height: 85px;
    display: block;
    width: 180px;
  }
}

.grupoPefacoLogoIcon {
  object-fit: contain;
  margin: auto;
  width: 110px;

  @media #{$mobile} {
    max-height: 60px;
    display: block;
    width: 150px;
  }
}


.logoMoney {
  width: 180px;
  object-fit: contain;
  margin: auto;

  @media #{$mobile} {
    max-height: 25px;
    display: block;
  }
}




.logoLonaci {
  width: 180px;
  object-fit: contain;
  margin: auto;

  @media #{$mobile} {
    max-height: 35px;
    display: block;
    width: 140px;
  }
}

.logoHalote {
  width: 200px;

  @media #{$mobile} {
    max-height: 40px;
    display: block;
  }
}



.lonaseLogoIcon {
  width: 130px;

  @media #{$mobile} {
    max-height: 55px;
    display: block;
    width: 150px;
  }
}

.RisklogoIcon {
  width: 150px;

  @media #{$mobile} {
    max-height: 45px;
    display: block;
    width: 100px;
  }
}