@import "../../sass/style.scss";

.overViewComponent {
  @include flex($justify_content: space-between);
  padding: 82px 0 0 0;

  @media #{$mobile} {
    display: block;
    padding:0;
  }
}

.overViewContainer {
  width: 50%;

  @media #{$mobile} {
    width: 100%;
    margin: auto;
  }
}

.overVeiwTitle {
  display: block;
  @include subTitles($white,
    $rubik,
    400,
    45px,
    left,
    14px,
    left,
    15px,
    center,
    $white );

  @media #{$mobile} {
    display: block;
    margin-left: auto;
  }
}

.overVeiwSecondTitle {
  color: $mainColor;
}

.overVeiwParagraph {
  @include subTitles($white,
    $rubik,
    0,
    20px,
    left,
    14px,
    left,
    15px,
    center,
    $white );

  @media #{$mobile} {
    display: block;
  }
}

.overVeiwImage {
  @include shapeWrapper(508px,
    508px,
    100%,
    4px,
    $mainColor,
    350px,
    350px,
    269px,
    269px,
    4px,
    $mainColor,
    476px,
    476px,
    100%,
    none,
    $white,
    376px,
    376px,
    196px,
    196px,
    none,
    $white );

  @media #{$mobile} {
    display: none;
  }
}

.ImageContainer {
  @media #{$mobile} {
    width: fit-content;
    margin: auto;
    display: block;
  }
}

.imageStyle {
  width: 476px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$tabDesk} {
    width: 330px;
  }

  @media #{$mobile} {
    max-width: 0;
  }
}

.iconArrowsStyle {
  @include flex();

  @media #{$mobile} {
    align-items: center;
    width: 100%;

  }
}

.overVewiSmallCircles {
  position: relative;
  @include shapeWrapper(180px,
    180px,
    100%,
    4px,
    $mainColor,
    150px,
    150px,
    210px,
    210px,
    0,
    $mainColor,
    192px,
    192px,
    100%,
    2px,
    $white,
    130px,
    130px,
    196px,
    196px,
    none,
    $white );

  @media #{$mobile} {
    &::before {
      border-width: 0;
    }
  }
}

.iconTitle {
  width: 50%;
  @include flex($justify_content: space-between);
}

.smallImageStyle {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;

  @media #{$tabDesk} {
    width: 70px;
  }

  @media #{$mobile} {
    width: 70px;
  }
}

.overVewiWrapper {
  @include flex($justify_content: flex-start);
  margin: auto;
  gap: 30px;
  margin: -18px auto 100px auto;
  position: relative;

  @media #{$tabDesk} {
    margin: -10px auto 100px auto;
    width: 95%;
  }

  @media #{$mobile} {
    margin: 0;
    display: block;
    width: 100%;
  }
}

.titleUnderIcon {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  margin: auto;
  @include subTitles($white,
    $rubik,
    500,
    14px,
    center,
    10px,
    center,
    14px,
    center,
    $white );

  &.firstTitle {
    bottom: 15%;
  }

  &.secondTitle {
    bottom: 0%;

    @media #{$tabDesk} {
      bottom: 5%;

    }
  }
}

.arrowStyle {
  background-color: $white;
  position: absolute;
  height: 1px;

  &.arrow1 {
    right: -50%;
    top: 20%;
    width: 81px;

    @media #{$mobile} {
      display: none;
    }

    @media #{$tabDesk} {
      right: -60%;
      top: 15%;
    }
  }

  &.arrow2 {
    right: -60%;
    top: 40%;
    width: 81px;

    @media #{$mobile} {
      display: none;
    }

    @media #{$tabDesk} {
      right: -70%;
      top: 35%;
    }
  }

  &.arrow3 {
    right: -60%;
    top: 60%;
    width: 81px;

    @media #{$mobile} {
      display: none;
    }

    @media #{$tabDesk} {
      right: -70%;
      top: 55%;
    }
  }

  &.arrow4 {
    right: -50%;
    top: 80%;
    width: 81px;

    @media #{$mobile} {
      display: none;
    }

    @media #{$tabDesk} {
      right: -60%;
      top: 75%;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $white;
    display: inline-block;
    transform: rotate(40deg);
    top: -6px;
    right: 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $white;
    display: inline-block;
    transform: rotate(40deg);
    top: -5px;
    left: 0;
  }
}

.arrowContMobile {
  @media #{$mobile} {
    width: 30%;
  }
}

.arrowStyleMobile {
  display: none;

  @media #{$mobile} {
    display: block;
    background-color: $white;
    width: 70%;
    margin-right: auto;
    position: relative;
    height: 1px;

    &.arrowMobile1 {
      top: 25%;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $white;
      display: inline-block;
      transform: rotate(40deg);
      top: -4px;
      right: 0;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $white;
      display: inline-block;
      transform: rotate(40deg);
      top: -4px;
      left: 0;
    }
  }
}

.subTitlesLeftPosition {
  margin-bottom: -20px;

  @media #{$tabDesk} {
    margin-bottom: 0;
  }

  @media #{$mobile} {
    width: 158px;
  }
}

.subtitleBesideArrow {
  @include flex();
  margin-bottom: 20px;
  display: block;
  @include subTitles($white,
    $rubik,
    500,
    16px,
    center,
    12px,
    left,
    14px,
    left,
    $white );

  @media #{$tabDesk} {
    margin-bottom: 15px;
  }

  &.subtitle1 {
    margin-left: 90px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 95px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }

  &.subtitle2 {
    margin-left: 120px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 110px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }

  &.subtitle3 {
    margin-left: 120px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 110px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }

  &.subtitle4 {
    margin-left: 120px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 95px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }

  &.eWalletSubtitle {
    margin-left: 40px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 95px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }

  &.mobileMoneySubtitle {
    margin-left: 100px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 105px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }

  &.paymentGatewaySubtitle {
    margin-left: 140px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 105px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }

  &.banksSubtitle {
    margin-left: 20px;

    @media #{$mobile} {
      margin: 0 0 20px 0;
    }

    @media #{$tabDesk} {
      margin-left: 95px;
    }

    @media #{$ipad} {
      margin-left: 0;
    }
  }
}